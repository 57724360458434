import { GET_CURRENCY, SET_CURRENCY } from './CurrencyActions';

const initialState = {};

const CurrencyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENCY:
      return {
        ...payload,
      };

    case GET_CURRENCY:
      return state;

    default:
      return state;
  }
};

export default CurrencyReducer;

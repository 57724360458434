const dev = {
  production: false,
  api: {
    URL: 'https://api-staging.fenix.cash',
  },
  auth: {
    CLIENT_ID: '4hxJybafk4EfSlrr',
    CLIENT_SECRET: 'xfuiFEr7bQwUSqqVniWhfFMtbgQ8KjN6',
  },
  share: {
    URL: 'https://share-staging.fenix.cash/',
  },
  web: {
    URL: 'https://web-staging.fenix.cash',
  },
  google: {
    MAPS: 'AIzaSyAJwjvBS8PVxKFtbWciwV_mFxRsqo3_2MM',
  },
  publicWebsite: {
    URL: `https://www.fenix360.net`,
  },
};

const prod = {
  production: true,
  api: {
    URL: 'https://api.fenix360.com',
  },
  auth: {
    CLIENT_ID: 'sPuK3BKn3Gb9tXn0',
    CLIENT_SECRET: 'B0CJQQAbkkzLT7EZjSvKREvVD9OOIsa8',
  },
  share: {
    URL: 'https://share.fenix360.com',
  },
  web: {
    URL: 'https://web.fenix360.com',
  },
  google: {
    MAPS: 'AIzaSyAJwjvBS8PVxKFtbWciwV_mFxRsqo3_2MM',
  },
  publicWebsite: {
    URL: `https://www.fenix360.net`,
  },
};

console.log({ version: '0.0.1' });
console.log({ REACT_APP_APP_ENV: process.env.REACT_APP_APP_ENV });

const config = process.env.REACT_APP_APP_ENV === 'production' ? prod : dev;

export default config;

import axios from 'axios';
import config from '../config';
import { md5 } from '../helpers/helper';
import history from '../helpers/history';

axios.defaults.baseURL = config.api.URL;

/**
 * Global request function for handling all HTTP calls
 * @param method
 * @param url
 * @param options {params: <for query params>, payload: <for data to be sent>'}
 */

const request = (method, url, options = { params: {}, payload: {} }) => {
  const request = {
    url,
    method,
    params: options.params,
    data: options.payload,
  };

  return new Promise((resolve, reject) => {
    axios
      .request(request)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.error));
  });
};

export const POST = (path, payload) => {
  return request('POST', path, { payload });
};

export const GET = (path, params) => {
  return request('GET', path, { params });
};

export const GETALL = (path, filters) => {
  return request('GET', path, { params: filters });
};

export const PUT = (path, payload) => {
  return request('PUT', path, { payload: payload });
};

export const PATCH = (path, payload) => {
  return request('PATCH', path, { payload: payload });
};

export const DELETE = (path) => {
  return request('DELETE', path);
};

export const FETCH_SONG = (song) => {
  const token = localStorage.getItem('_utTrack');
  const currentTime = Date.now();
  const payloadString = `${token}-${song.unique_id}-${song.id}-${song.artist_id}-${currentTime}`;
  const payload = md5(payloadString);
  const url = `${config.api.URL}/song/get_tune/${payload}/${currentTime}?token=${token}`;
  return url;
};

axios.interceptors.request.use((request) => {
  // Setting up headers
  const token = localStorage.getItem('arkey');
  const loggedInUserToken = localStorage.getItem('ARTPWATKEN');
  let headers = {
    'X-CLIENT-ID': config.auth.CLIENT_ID,
    'X-CLIENT-SECRET': config.auth.CLIENT_SECRET,
  };
  if (token) {
    headers['X-FNX-PWA-ID'] = token;
  }
  if (loggedInUserToken) {
    headers['Authorization'] = loggedInUserToken;
  }

  request.headers = headers;
  return request;
});

/**
 * RESPONSE INTERCEPTOR
 */
axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.code === 401) {
      localStorage.removeItem('ARTPWATKEN');
      window.location.reload();
    }
    return Promise.reject(error.response.data);
  },
);

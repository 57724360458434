import { StyleSheet } from 'react-native';
import { baseFontFamily } from './common.app';
export default StyleSheet.create({
  bgImage: {
    height: '100%',
    width: '100%',
    resizeMode: 'contain',
    backgroundSize: 'contain',
    // backgroundPosition: 'top center',
    // position: 'absolute',
    // top: 0,
    // left: 0,
    backgroundColor: 'var(--app-bg-color)',
  },
  onlyBgColor: {
    height: '100%',
    width: '100%',
    backgroundColor: 'var(--secondary-dark-gray)',
  },
  bgVideo: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  StylenoImage: {
    backgroundColor: 'transparent',
  },
  linearGradient: {
    // flex: 1,
    // alignSelf: 'stretch',
    // height: '50vh',
    // overflowY: 'scroll',

    // old
    // flex: 1,
    // alignSelf: 'stretch',
    height: '100vh',
  },
  PageWrapper: {
    maxWidth: 426,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonText: {
    fontSize: 18,
    fontFamily: 'Gill Sans',
    textAlign: 'center',
    margin: 10,
    color: 'var(--text-color)',
    backgroundColor: 'transparent',
  },
  appFooter: {
    zIndex: 15,
    position: 'fixed',
    left: 0,
    bottom: 0,
    paddingBottom: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  swipeText: {
    ...baseFontFamily,
    marginTop: 8,
    color: 'var(--text-color)',
  },
});

import { Formik } from 'formik';
import InputMaterialUi from 'input-material-ui';
import React, { useRef, useState } from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native-web';
import LinearGradient from 'react-native-web-linear-gradient';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import StyleApps from '../../../assets/common-style/common.app';
import StyleBtn from '../../../assets/common-style/common.btn';
import StyleLanding from '../../../assets/common-style/common.landing';
import Styleutility from '../../../assets/common-style/common.utility';
import { IconArrowBack } from '../../../components/Icons';
import config from '../../../config';
import { openInNewTab } from '../../../helpers/helper';
import history from '../../../helpers/history';
import { loginAction } from '../../../redux/user/UserActions';
import { EmailPhoneInput } from './EmailPhoneInput';
import TextField from '@mui/material/TextField';

const Login = (props) => {
  const dispatch = useDispatch();
  const [scrollEnabled, setEnabled] = useState(true);
  const [throttle, setThrottle] = useState(16);
  const scrollRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState('+1');

  return (
    <>
      <LinearGradient
        useAngle
        angle={135}
        colors={['var(--secondary-dark-gray) 0', 'var(--secondary-dark-gray) 100%']}
        style={[StyleLanding.linearGradient, StyleLanding.PageWrapper]}
      >
        <View style={StyleApps.header}>
          <View style={StyleApps.headerContent}>
            <Text onPress={() => history.goBack()} style={StyleApps.back}>
              <IconArrowBack />
            </Text>
            <Text style={StyleApps.pagetitle}>Login</Text>
          </View>
        </View>
        <ScrollView
          onScroll={() => {}}
          ref={scrollRef}
          scrollEnabled={scrollEnabled}
          scrollEventThrottle={throttle}
          style={[StyleApps.scrollView]}
          contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
        >
          <Formik
            initialValues={{
              type: 'email',
              fenix_id: '',
              password: '',
              phoneCode: '+1',
            }}
            onSubmit={async (values, { resetForm }) => {
              //  values

              if (values.type === 'phone') {
                const payload = {
                  fenix_id: `${values.phoneCode}${values.fenix_id}`,
                  password: values.password,
                };
                dispatch(loginAction(payload));
              }
              if (values.type === 'email') {
                const payload = {
                  fenix_id: values.fenix_id,
                  password: values.password,
                };
                dispatch(loginAction(payload));
              }
            }}
            validationSchema={object().shape({
              fenix_id: string().when('type', {
                is: (type) => {
                  return type === 'email' ? true : false;
                },
                then: () => string().trim().email('Enter valid email').required('Email is required'),
                otherwise: () => string().trim().required('Phone number is required'),
              }),
              password: string().trim().required('Password is required').min(8, 'Password must contain at least 6 characters'),
            })}
          >
            {(renderProps) => {
              const { values, errors, setFieldValue, handleChange, handleBlur, handleSubmit, setFieldTouched, setFieldError, isValid, dirty } = renderProps;

              return (
                <form>
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <View style={[StyleApps.AppSection, Styleutility.mt0, Styleutility.w100]}>
                      <View style={StyleApps.AppList}>
                        <EmailPhoneInput values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} errors={errors} />
                        <View style={[StyleApps.FormGroup]}>
                          <TextField
                            variant="standard"
                            type="password"
                            label="Password"
                            placeholder="Enter Password"
                            onChange={(e) => {
                              setFieldValue('password', e.target.value || '');
                            }}
                            onBlur={handleBlur}
                            id="password"
                            name="password"
                            value={values.password}
                          />
                        </View>

                        <View style={[Styleutility.mb24, Styleutility.mt16]}>
                          <TouchableOpacity style={StyleBtn.BtnPrimaryGradient} onPress={handleSubmit} disabled={!(isValid && dirty)}>
                            <Text style={[StyleBtn.BtnPrimaryGradientText]}>Login</Text>
                          </TouchableOpacity>
                        </View>
                        <View>
                          <TouchableOpacity onPress={() => openInNewTab(`${config.web.URL}/forgot-password`)}>
                            <Text style={[Styleutility.textCenter, Styleutility.textPrimary]}>Forgot Password?</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </form>
              );
            }}
          </Formik>
        </ScrollView>
      </LinearGradient>
    </>
  );
};

export default Login;

/* ACTIONS */

export const GET_ARTIST = 'GET_ARTIST';
export const SET_ARTIST = 'SET_ARTIST';
export const CLEAR_ARTIST = 'CLEAR_ARTIST';

/* ACTION CREATORS */

export const setArtist = (artist) => {
  return (dispatch) => {
    dispatch({
      type: SET_ARTIST,
      payload: artist,
    });
  };
};

export const getArtist = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ARTIST,
      payload: {},
    });
  };
};

export const clearArtist = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ARTIST,
      payload: {},
    });
  };
};

import { Formik } from 'formik';
import InputMaterialUi from 'input-material-ui';
import React, { useRef, useState } from 'react';
import { Picker, ScrollView, Text, TouchableOpacity, View } from 'react-native-web';
import LinearGradient from 'react-native-web-linear-gradient';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import StyleApps from '../../../assets/common-style/common.app';
import StyleBtn from '../../../assets/common-style/common.btn';
import StyleLanding from '../../../assets/common-style/common.landing';
import Styleutility from '../../../assets/common-style/common.utility';
import { IconArrowBack } from '../../../components/Icons';
import history from '../../../helpers/history';
import phoneCodeList from '../../../assets/json/country.json';

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const [scrollEnabled, setEnabled] = useState(true);
  const [throttle, setThrottle] = useState(16);
  const scrollRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState('+1');

  return (
    <>
      <LinearGradient
        useAngle
        angle={135}
        colors={['var(--secondary-dark-gray) 0', 'var(--secondary-dark-gray) 100%']}
        style={[StyleLanding.linearGradient, StyleLanding.PageWrapper]}
      >
        <View style={StyleApps.header}>
          <View style={StyleApps.headerContent}>
            <Text onPress={() => history.goBack()} style={StyleApps.back}>
              <IconArrowBack />
            </Text>
            <Text style={StyleApps.pagetitle}>Forgot Password</Text>
          </View>
        </View>
        <ScrollView
          onScroll={() => {}}
          ref={scrollRef}
          scrollEnabled={scrollEnabled}
          scrollEventThrottle={throttle}
          style={[StyleApps.scrollView]}
          contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
        >
          <Formik
            initialValues={{
              type: 'email',
              fenix_id: '',
              phoneCode: '+93',
            }}
            onSubmit={async (values, { resetForm }) => {
              //  values
              const payload = {};
              if (values.type === 'phone') {
                payload.fenix_id = `${values.phoneCode}${values.fenix_id}`;
              }
              if (values.type === 'email') {
                payload.fenix_id = values.fenix_id;
              }
            }}
            validationSchema={object().shape({
              fenix_id: string().when('type', {
                is: (type) => {
                  return type === 'email' ? true : false;
                },
                then: () => string().email('Enter valid email').required('Email is required'),
                otherwise: () => string().required('Phone number is required'),
              }),
            })}
          >
            {(renderProps) => {
              const { values, errors, setFieldValue, handleChange, handleBlur, handleSubmit, setFieldTouched, setFieldError } = renderProps;

              return (
                <form>
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <View style={[StyleApps.AppSection, Styleutility.mt0, Styleutility.w100]}>
                      <View style={StyleApps.AppList}>
                        {values.type === 'phone' ? (
                          <View style={[StyleApps.FormGroup]}>
                            <View style={[Styleutility.FlexBox]}>
                              <View style={(Styleutility.mt10, Styleutility.mr16)}>
                                <Text style={[Styleutility.text12]}>Code</Text>
                                <View style={[StyleApps.InputLine, StyleApps.InputLineLight]}>
                                  <Picker
                                    selectedValue={values.phoneCode}
                                    style={[StyleApps.inputPicker, { width: 69 }]}
                                    onValueChange={(itemValue) => setFieldValue('phoneCode', itemValue)}
                                  >
                                    {phoneCodeList.map((x, index) => (
                                      <Picker.Item color="black" label={`+${x.phonecode}`} value={`+${x.phonecode}`} key={index} />
                                    ))}
                                  </Picker>
                                </View>
                              </View>
                              <InputMaterialUi
                                type="number"
                                label="Phone Number"
                                placeholder="Enter Phone Number"
                                onChange={(e) => {
                                  setFieldValue('fenix_id', e || '');
                                }}
                                onBlur={handleBlur}
                                id="fenix_id"
                                name="fenix_id"
                                value={values.fenix_id}
                              />{' '}
                            </View>
                            <View style={Styleutility.mt10}>
                              <TouchableOpacity
                                onPress={() => {
                                  setFieldValue('type', 'email');
                                  setFieldValue('fenix_id', '');
                                }}
                              >
                                <Text style={[Styleutility.text14]}>Switch to email</Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        ) : (
                          <View style={[StyleApps.FormGroup]}>
                            <InputMaterialUi
                              type="text"
                              label="Email"
                              placeholder="Enter Email"
                              onChange={(e) => {
                                setFieldValue('fenix_id', e || '');
                              }}
                              onBlur={handleBlur}
                              id="fenix_id"
                              name="fenix_id"
                              value={values.fenix_id}
                            />
                            <View style={Styleutility.mt10}>
                              <TouchableOpacity
                                onPress={() => {
                                  setFieldValue('type', 'phone');
                                  setFieldValue('fenix_id', '');
                                }}
                              >
                                <Text style={[Styleutility.text14]}>Switch to mobile</Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        )}

                        <View style={[Styleutility.mb24, Styleutility.mt16]}>
                          <TouchableOpacity style={StyleBtn.BtnPrimaryGradient}>
                            <Text style={[StyleBtn.BtnPrimaryGradientText]}>Send</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </form>
              );
            }}
          </Formik>
        </ScrollView>
      </LinearGradient>
    </>
  );
};

export default ForgotPassword;

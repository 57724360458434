import { StyleSheet } from 'react-native';
import { baseFontFamily } from './common.app';

export default StyleSheet.create({
  DeviceStyle: {
    zIndex: '1090',
  },

  DeviceContent: {
    zIndex: '1090',
    // paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  DeviceContentiOS: {
    paddingTop: 108,
  },
  DeviceiOS: {
    minHeight: '100vh',
    // minHeight: 'calc(100vh - 90px)',
    // minHeight: 'calc(100vh - 108px)',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  DeviceAndroid: {
    // minHeight: 'calc(100vh - 200px)',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  ArtistLogoCenterY: {
    minHeight: 'calc(100vh - 140px)',
    justifyContent: 'center',
  },
  DeviceHeading: {
    ...baseFontFamily,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 30,
    marginBottom: 4,
    color: 'var(--text-color)',
  },
  DeviceHeadingLg: {
    fontSize: 32,
    lineHeight: 40,
  },
  DeviceSubHeading: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 18,
    color: 'var(--text-color)',
  },
  DeviceInstallInfo: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 45,
    //marginBottom: 45,
    textAlign: 'center',
  },
  DeviceInstallInfoText: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 21,
    color: 'var(--text-color)',
    marginTop: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 15,
    padding: 5,
  },
  DeviceContentOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 15,
    padding: 5,
  },
  DeviceInstallInfoLink: {
    ...baseFontFamily,
    // fontWeight: 500,
    // fontSize: 14,
    // lineHeight: 18,
    color: 'var( --assets-color)',
    textDecorationLine: 'underline',
  },
  DeviceInstallIcon: {
    position: 'absolute',
    left: 0,
    bottom: 125,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  DeviceInstallIconiOs: {
    transform: 'rotate(-180deg)',
  },
  DeviceInstallIconAndoid: {
    top: 30,
    right: 12,
    bottom: 'auto',
    left: 'auto',
    width: 'auto',
  },
  DeviceInstallInfoDekstop: {
    marginTop: 'auto',
  },
  DeviceStyleDesktop: {
    minHeight: '100vh',
    justifyContent: 'center',
    maxWidth: 450,
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  ArtistLogoDesktop: {
    marginTop: 90,
    // paddingBottom: 45,
  },
  StickNote: {
    backgroundColor: 'var(--assets-color)',
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1099,
    padding: 10,
  },
  StickNoteBgWhite: {
    backgroundColor: '#ffffff',
  },
  StickNoteAppDownload: {
    position: 'relative',
  },
  top0: {
    top: 0,
  },
  BtnDownloadApp: {
    ...baseFontFamily,
    fontWeight: 500,
    color: '#ffffff',
    marginTop: 'auto',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)',
  },
  BtnDownloadText: {
    color: '#ffffff',
  },
  StickNoteDownload: {
    flexDirection: 'row',
  },
  StickNoteDownloadRow: {
    ...baseFontFamily,
    alignItems: 'flex-start',
    fontWeight: 400,
    color: 'var(--text-color)',
    width: '100%',
    flex: 1,
  },
  StickNoteText: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: 20,
    color: 'var(--text-color)',
    marginRight: 24,
  },
  StickNoteTextDark: {
    color: '#000000',
  },
  StickNoteTextSm: {
    fontSize: 11,
    lineHeight: 13,
    marginRight: 20,
  },
  StickNoteTextBold: {
    fontWeight: 600,
    marginBottom: 6,
  },
  StickNoteClose: {
    position: 'absolute',
    right: 4,
    top: 8,
    padding: 6,
  },
  StickNoteCloseAppDpwnload: {
    position: 'relative',
    alignItems: 'flex-start',
    paddingRight: 0,
    paddingTop: 2,
    top: 0,
    right: 0,
    marginLeft: 3,
  },
  fontitalic: {
    fontStyle: 'italic',
  },
  StickNoteAndroid: {
    top: 'auto',
    bottom: 0,
  },
  zIndexLg: {
    zIndex: 1099,
  },
  desktopView: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    // minHeight: '100vh',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  desktopViewCol: {
    flex: 1,
    width: '50%',
  },
  desktopViewColRight: {
    height: '100%',
  },
});

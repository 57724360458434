import InputMaterialUi from 'input-material-ui';
import { lowerCase } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FlatList, Modal, Text, TextInput, TouchableOpacity, View } from 'react-native-web';
import StyleApps from '../../../assets/common-style/common.app';
import Styleutility from '../../../assets/common-style/common.utility';
import phoneCodeList from '../../../assets/json/country.json';
import useDebounce from '../../../components/hoc/CustomSearchHook';
import { IconCross, IconSearch } from '../../../components/Icons';
import { TextField } from '@material-ui/core';

export const EmailPhoneInput = ({ values, setFieldValue, handleBlur, errors }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [codeList, setCodeList] = useState(phoneCodeList);
  const [searchValue, setSearchValue] = useState('');
  const debouncedUserInput = useDebounce(searchValue, 500);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      debouncesearch(debouncedUserInput);
    }
  }, [debouncedUserInput]);

  const debouncesearch = (debouncedUserInput) => {
    if (debouncedUserInput) {
      const filterData = phoneCodeList.filter((x) => x.name.toLowerCase().includes(lowerCase(debouncedUserInput)));
      setCodeList(filterData);
    } else {
      setCodeList(phoneCodeList);
    }
  };

  return (
    <>
      {values.type === 'phone' ? (
        <View style={[StyleApps.FormGroup]}>
          <View style={[Styleutility.FlexBox]}>
            <View style={(Styleutility.mt10, Styleutility.mr16)}>
              <Text style={[Styleutility.inputCode]}>Code</Text>
              <View style={[StyleApps.InputLine, StyleApps.InputLineLight]}>
                {/* <Picker selectedValue={values.phoneCode} style={[StyleApps.inputPicker, { width: 69 }]} onValueChange={(itemValue) => setFieldValue('phoneCode', itemValue)}>
                  {phoneCodeList.map((x, index) => (
                    <Picker.Item color="black" label={`+${x.phonecode}`} value={`+${x.phonecode}`} key={index} />
                  ))}
                </Picker> */}
                <TouchableOpacity onPress={() => setModalVisible(true)}>
                  <Text style={[Styleutility.text14]}>{values.phoneCode}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={[Styleutility.w100]}>
              <TextField
                variant="standard"
                type="number"
                label="Phone Number"
                placeholder="Enter Phone Number"
                onChange={(e) => {
                  setFieldValue('fenix_id', e.target.value || '');
                }}
                onBlur={handleBlur}
                id="fenix_id"
                name="fenix_id"
                value={values.fenix_id}
              />
            </View>
          </View>
          <View style={Styleutility.mt10}>
            <TouchableOpacity
              onPress={() => {
                setFieldValue('type', 'email');
                setFieldValue('fenix_id', '');
              }}
            >
              <Text style={[Styleutility.text14]}>Switch to email</Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <View style={[StyleApps.FormGroup]}>
          <TextField
            variant="standard"
            type="text"
            label="Email"
            placeholder="Enter Email"
            onChange={(e) => {
              setFieldValue('fenix_id', e.target.value || '');
            }}
            onBlur={handleBlur}
            id="fenix_id"
            name="fenix_id"
            value={values.fenix_id}
          />
          <View style={Styleutility.mt10}>
            <TouchableOpacity
              onPress={() => {
                setFieldValue('type', 'phone');
                setFieldValue('fenix_id', '');
              }}
            >
              <Text style={[Styleutility.text14]}>Switch to mobile</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={StyleApps.modalMainView}>
          <View style={StyleApps.CraftsmodalWrapper}>
            <View style={[StyleApps.ModalBottomContent, StyleApps.ModalHauto]}>
              <View style={[StyleApps.ModalBottomHeader]}>
                <Text style={StyleApps.Text18}>Select Country</Text>
                <TouchableOpacity style={[StyleApps.CloseModal, StyleApps.CloseBottomModal]} onPress={() => setModalVisible(false)}>
                  <Text>
                    <IconCross />
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={[StyleApps.ModalBottomHeader]}>
                <View style={StyleApps.searchControl}>
                  <Text style={StyleApps.searchControlIcon}>
                    <IconSearch />
                  </Text>
                  <TextInput placeholder="Search here" value={searchValue} onChangeText={setSearchValue} style={StyleApps.textinput} />
                </View>
              </View>
              <View style={StyleApps.ModalBottomBody}>
                <FlatList
                  data={codeList}
                  renderItem={({ item, index }) => (
                    <View style={StyleApps.ModalListItem}>
                      <TouchableOpacity
                        onPress={() => {
                          setFieldValue('phoneCode', `+${item.phonecode}`);
                          setModalVisible(false);
                          setSearchValue('');
                        }}
                      >
                        <Text style={[Styleutility.font14, Styleutility.textWhite]}>
                          {`+${item.phonecode}`} {item.name}
                        </Text>
                      </TouchableOpacity>
                      {/* <div className="react-checkbox ">
                        <input
                          type="checkbox"
                          className='d-none'
                          id={index}
                          checked={values.phoneCode === `+${item.phonecode}`}
                          onChange={() => {
                            setFieldValue('phoneCode', `+${item.phonecode}`);
                            setModalVisible(false);
                            setSearchValue('');
                          }}
                        />
                        <label htmlFor={index}>
                        {`+${item.phonecode}`} {item.name}
                        </label>
                      </div> */}
                    </View>
                  )}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};

import { combineReducers } from 'redux';
import ArtistReducer from './artist/ArtistReducer';
import CurrencyReducer from './currency/CurrencyReducer';
import LoaderReducer from './loader/LoaderReducer';
import MusicReducer from './music/MusicReducer';
import NotificationReducer from './notification/NotificationReducer';
import UserReducer from './user/UserReducer';

const rootReducer = combineReducers({
  loading: LoaderReducer,
  artist: ArtistReducer,
  notification: NotificationReducer,
  music: MusicReducer,
  currency: CurrencyReducer,
  user: UserReducer,
});

export default rootReducer;

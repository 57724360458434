import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Image, Linking, Modal, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native-web';
import LinearGradient from 'react-native-web-linear-gradient';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { object, string } from 'yup';
import StyleApps from '../../../assets/common-style/common.app';
import StyleBtn from '../../../assets/common-style/common.btn';
import StyleLanding from '../../../assets/common-style/common.landing';
import Styleutility from '../../../assets/common-style/common.utility';
import ImgProfile1 from '../../../assets/img/profile-13.png';
import { IconAdd, IconArrowBack, IconArrowDown, IconClear, IconClearBold, IconCross, IconHelp, IconSearch } from '../../../components/Icons';
import useDebounce from '../../../components/hoc/CustomSearchHook';
import config from '../../../config';
import { openInNewTab } from '../../../helpers/helper';
import history from '../../../helpers/history';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { showNotif } from '../../../redux/notification/NotificationActions';
import { getCraftList, getSupportArtistList, signUp } from '../../../services/apiEndpoint';
import 'react-tooltip/dist/react-tooltip.css';

const Signup = (props) => {
  const dispatch = useDispatch();
  const [scrollEnabled, setEnabled] = useState(true);
  const [throttle, setThrottle] = useState(16);
  const scrollRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState('+1');
  const [modalVisible, setModalVisible] = useState(false);
  const [isSelected, setSelection] = useState(false);
  const [craftList, setCraftList] = useState([]);
  const [selectedCraftList, setSelectedCraftList] = useState([]);

  const [userType, setUserType] = useState('');

  const [searchValue, setSearchValue] = useState('');
  const [artistList, setArtistList] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(false);

  const debouncedUserInput = useDebounce(searchValue, 500);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      debouncesearch(debouncedUserInput);
    }
  }, [debouncedUserInput]);

  const debouncesearch = async (debouncedUserInput) => {
    if (debouncedUserInput.length >= 2) {
      try {
        let { data } = await getSupportArtistList({ filter_text: debouncedUserInput });
        setArtistList(data);
      } catch (err) {}
    } else {
      setArtistList([]);
    }
  };

  useEffect(() => {
    const getCraftListdata = async () => {
      try {
        let data = await getCraftList();
        setCraftList(data);
      } catch (err) {}
    };

    getCraftListdata();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          phone_number: '',
          password: '',
          email: '',
          artist_name: '',
          craft: [],
          selectedCraft: [],
          fclid: '',
          showArtistSelection: false,
        }}
        onSubmit={async (values, { resetForm }) => {
          //  values

          const payload = {
            password: values.password,
            email: values.email,
            craft: values.craft,

            user_type: userType === 'Artist' ? userType : '',
          };
          if (!payload.user_type) {
            delete payload.user_type;
          }
          if (values?.fclid?.unique_id) {
            payload.fclid = values?.fclid?.unique_id;
          }
          dispatch(showLoading());
          try {
            let { data } = await signUp(payload);
            dispatch(showNotif('Please check your email to confirm your account.'));
            history.push('/login');
          } catch (err) {
            dispatch(showNotif(err.message.toString()));
          } finally {
            dispatch(hideLoading());
          }
        }}
        validationSchema={object().shape({
          artist_name: string().trim().required('Artist name is required'),
          email: string().trim().email('Enter valid email').required('Email is required'),
          password: string().trim().required('Password is required'),
        })}
      >
        {(renderProps) => {
          const { values, errors, setFieldValue, handleChange, handleBlur, handleSubmit, setFieldTouched, setFieldError } = renderProps;

          return (
            <form>
              {!values.showArtistSelection ? (
                <LinearGradient
                  useAngle
                  angle={135}
                  colors={['var(--secondary-dark-gray) 0', 'var(--secondary-dark-gray) 100%']}
                  style={[StyleLanding.linearGradient, StyleLanding.PageWrapper]}
                >
                  <View style={StyleApps.header}>
                    <View style={StyleApps.headerContent}>
                      <Text onPress={() => history.goBack()} style={StyleApps.back}>
                        <IconArrowBack />
                      </Text>
                      <Text style={StyleApps.pagetitle}>Sign up</Text>
                    </View>
                  </View>

                  <ScrollView
                    onScroll={() => {}}
                    ref={scrollRef}
                    scrollEnabled={scrollEnabled}
                    scrollEventThrottle={throttle}
                    style={[StyleApps.scrollView]}
                    contentContainerStyle={!userType ? { flexGrow: 1, justifyContent: 'center' } : {}}
                  >
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                      <View style={[StyleApps.AppSection, Styleutility.mt0, Styleutility.w100]}>
                        {!userType ? (
                          <View style={StyleApps.AppList}>
                            <Text style={[Styleutility.text14w400, Styleutility.textCenter, Styleutility.mb32, Styleutility.textWhite]}>Are you an Artist or a Fan?</Text>
                            <View>
                              <TouchableOpacity style={StyleBtn.BtnPrimaryGradient} onPress={() => setUserType('Artist')}>
                                <Text style={[StyleBtn.BtnPrimaryGradientText]}>Artist</Text>
                              </TouchableOpacity>
                            </View>
                            <View style={[Styleutility.mt16]}>
                              <TouchableOpacity style={StyleBtn.BtnPrimaryGradient} onPress={() => setUserType('User')}>
                                <Text style={[StyleBtn.BtnPrimaryGradientText]}>Fan</Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        ) : (
                          <View style={StyleApps.AppList}>
                            <View style={[StyleApps.FormGroup]}>
                              <TextField
                                type="text"
                                label="Display Name"
                                placeholder="Enter Name"
                                onChange={(e) => {
                                  setFieldValue('artist_name', e.target.value || '');
                                }}
                                onBlur={handleBlur}
                                id="artist_name"
                                name="artist_name"
                                value={values.artist_name}
                                variant="standard"
                              />
                            </View>

                            {/* <View style={[StyleApps.FormGroup, Styleutility.mb8]}>
                            <View style={[Styleutility.FlexBox]}>
                              <View style={(Styleutility.mt10, Styleutility.mr16)}>
                                <Text style={[Styleutility.text12]}>Code</Text>
                                <View style={[StyleApps.InputLine, StyleApps.InputLineLight]}>
                                  <Picker
                                    selectedValue={selectedValue}
                                    style={[StyleApps.inputPicker, { width: 69 }]}
                                    onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}
                                  >
                                    <Picker.Item color="black" label="+1" value="+1" />
                                    <Picker.Item color="black" label="+2" value="+2" />
                                  </Picker>
                                </View>
                              </View>
                              <InputMaterialUi type="text" label="Phone Number" placeholder="Enter Phone Number" value={''} onChange={() => {}} />
                            </View>
                            <View style={Styleutility.mt10}>
                              <TouchableOpacity>
                                <Text style={[Styleutility.text14]}>Switch to Email Registration</Text>
                              </TouchableOpacity>
                            </View>
                          </View> */}

                            {/* <View style={[StyleApps.FormGroup]}>
                              <TextField id="outlined-password-input" label="Password" type="password" variant="outlined" autoComplete="current-password" />
                            </View> */}
                            <View style={[StyleApps.FormGroup]}>
                              <TextField
                                variant="standard"
                                type="text"
                                label="Email"
                                placeholder="Enter Email"
                                onChange={(e) => {
                                  setFieldValue('email', e.target.value || '');
                                }}
                                onBlur={handleBlur}
                                id="email"
                                name="email"
                                value={values.email}
                              />
                            </View>
                            <View style={[StyleApps.FormGroup]}>
                              <TextField
                                variant="standard"
                                type="password"
                                label="Password"
                                placeholder="Enter Password"
                                onChange={(e) => {
                                  setFieldValue('password', e.target.value || '');
                                }}
                                onBlur={handleBlur}
                                id="password"
                                name="password"
                                value={values.password}
                              />
                            </View>
                            <View style={[StyleApps.FormGroup]}>
                              <View style={[StyleApps.InputLine, StyleApps.InputLineLight]}>
                                {!values.fclid ? (
                                  <View style={Styleutility.FlexBox}>
                                    <Text style={[Styleutility.text14, Styleutility.textplaceholder]}>Support an Artist</Text>
                                    <View style={(Styleutility.FlexCenter, Styleutility.dFlex)}>
                                      {/* <TouchableOpacity style={[Styleutility.mx5]}>
                                        <IconHelp />
                                      </TouchableOpacity>
                                       */}
                                      <View style={[Styleutility.mx5]}>
                                        <p
                                          className="react-tooltip"
                                          data-tooltip-content="Select the artist that you want to support, and the artist that you support (at no cost to you) will receive ad revenue from the time that you spend on the app!"
                                          data-tooltip-place="left"
                                          data-tooltip-offset="{'top': 0, 'left': 5}"
                                          data-tooltip-solid={true}
                                          data-tooltip-id="help-tool-tip"
                                        >
                                          <IconHelp />
                                        </p>
                                      </View>
                                      <TouchableOpacity
                                        onPress={() => {
                                          setFieldValue('showArtistSelection', true);
                                        }}
                                      >
                                        <IconAdd />
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                ) : (
                                  <>
                                    <Text style={[Styleutility.text14, Styleutility.textplaceholder]}>Support an Artist</Text>
                                    <View style={Styleutility.FlexBox}>
                                      <View style={[StyleApps.UserProfile, Styleutility.BorderNone, Styleutility.pb0]}>
                                        <View style={[StyleApps.AppItemMedia, StyleApps.ProfileMedia]}>
                                          <Image source={values?.fclid?.profile_image || ImgProfile1} style={[StyleApps.AppItemImg, StyleApps.ProfileImg]} />
                                        </View>
                                        <View style={StyleApps.ProfileInfo}>
                                          <Text numberOfLines={1} style={[Styleutility.text14, Styleutility.mr16]}>
                                            {values?.fclid?.artist_name}
                                          </Text>
                                          <View style={Styleutility.mr16}>
                                            <TouchableOpacity
                                              onPress={() => {
                                                setFieldValue('fclid', '');
                                                setSelectedArtist('');
                                              }}
                                            >
                                              <IconClearBold />
                                            </TouchableOpacity>
                                          </View>
                                        </View>

                                        <View style={(Styleutility.FlexCenter, Styleutility.dFlex)}>
                                          <p
                                            className="react-tooltip"
                                            data-tooltip-id="help-tool-tip"
                                            data-tooltip-content="Select the artist that you want to support, and the artist that you support (at no cost to you) will receive ad revenue from the time that you spend on the app!"
                                            data-tooltip-place="left"
                                            data-tooltip-offset="{'top': 0, 'left': 5}"
                                            data-tooltip-solid={true}
                                            data-tooltip-wrapper="div"
                                          >
                                            <IconHelp />
                                          </p>

                                          <TouchableOpacity
                                            style={[Styleutility.ml6]}
                                            onPress={() => {
                                              setFieldValue('showArtistSelection', true);
                                            }}
                                          >
                                            <IconAdd />
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                    </View>
                                  </>
                                )}
                              </View>
                              <Tooltip id="help-tool-tip" className="tooltip-artist-support" />
                              <View style={[Styleutility.mt10, Styleutility.text14, Styleutility.dFlexColumn]}>
                                <Text style={[Styleutility.text12w500, Styleutility.textSecondaryGray]}>Not seeing your artist? </Text>
                                <View style={Styleutility.dFlex}>
                                  <TouchableOpacity
                                    onPress={() => {
                                      Linking.openURL('mailto:hello@fenix.cash?subject=Feedback&body=');
                                    }}
                                  >
                                    <Text style={[Styleutility.text12w500, Styleutility.textPrimary]}>Contact us</Text>
                                  </TouchableOpacity>
                                  <Text style={[Styleutility.text12w500, Styleutility.textSecondaryGray]}> to request them</Text>
                                </View>
                              </View>
                            </View>
                            {userType !== 'User' ? (
                              <View style={[StyleApps.FormGroup]}>
                                <View style={[StyleApps.InputLine, StyleApps.InputLineLight]}>
                                  <TouchableOpacity
                                    onPress={() => {
                                      setSelectedCraftList(values.craft);
                                      setModalVisible(true);
                                    }}
                                  >
                                    <View style={Styleutility.FlexBox}>
                                      {values.craft.length <= 0 ? (
                                        <Text style={[Styleutility.text14, Styleutility.textplaceholder]}>Craft</Text>
                                      ) : (
                                        <>
                                          {' '}
                                          <Text style={[Styleutility.text14]}>{values.craft.length} Selected</Text>
                                        </>
                                      )}
                                      <View style={(Styleutility.FlexCenter, Styleutility.dFlex)}>
                                        <IconArrowDown fill={'#909096'} />
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            ) : (
                              ''
                            )}
                          </View>
                        )}
                      </View>
                    </View>
                  </ScrollView>
                  {userType ? (
                    <View style={StyleApps.footer}>
                      <View style={StyleApps.footerContent}>
                        <View style={[Styleutility.mt16]}>
                          <TouchableOpacity style={StyleBtn.BtnPrimaryGradient} onPress={handleSubmit}>
                            <Text style={[StyleBtn.BtnPrimaryGradientText]}>Sign Up</Text>
                          </TouchableOpacity>
                        </View>
                        <View style={[Styleutility.mw270, Styleutility.my16]}>
                          <Text style={[Styleutility.text14w500, Styleutility.TextLightGray]}>
                            By signing up you agree to our{' '}
                            <TouchableOpacity
                              onPress={() => {
                                openInNewTab(`https://cdn.fenix360.com/v1/FENIX_Privacy_and_Data_Protection_Policy.pdf`);
                              }}
                            >
                              <Text style={[Styleutility.textPrimary]}>Privacy Policy</Text>
                            </TouchableOpacity>{' '}
                            and{' '}
                            <TouchableOpacity
                              onPress={() => {
                                openInNewTab(`https://cdn.fenix360.com/v1/FenixCash-terms-and-conditions.pdf`);
                              }}
                            >
                              <Text style={[Styleutility.textPrimary]}>Terms & Conditions</Text>
                            </TouchableOpacity>
                          </Text>
                        </View>
                      </View>
                    </View>
                  ) : null}
                </LinearGradient>
              ) : (
                <LinearGradient
                  useAngle
                  angle={135}
                  colors={['var(--secondary-dark-gray) 0', 'var(--secondary-dark-gray) 100%']}
                  style={[StyleLanding.linearGradient, StyleLanding.PageWrapper]}
                >
                  <View style={StyleApps.header}>
                    <View style={StyleApps.headerContent}>
                      <Text onPress={() => setFieldValue('showArtistSelection', false)} style={StyleApps.back}>
                        <IconArrowBack fill="var(--icon-color)" />
                      </Text>
                      <Text style={StyleApps.pagetitle}>Support an Artist</Text>
                    </View>
                  </View>
                  <View style={StyleApps.searchBar}>
                    <View style={StyleApps.searchControl}>
                      <Text style={StyleApps.searchControlIcon}>
                        <IconSearch />
                      </Text>

                      <TextInput name="searchCountry" placeholder="Search photos…." value={searchValue} onChangeText={setSearchValue} style={StyleApps.textinput} />

                      <Text style={StyleApps.searchCrossIcon}>
                        <TouchableOpacity onPress={() => setSearchValue('')}>
                          <IconClear />
                        </TouchableOpacity>
                      </Text>
                    </View>
                  </View>
                  <ScrollView onScroll={() => {}} ref={scrollRef} scrollEnabled={scrollEnabled} scrollEventThrottle={throttle} style={[StyleApps.scrollView]}>
                    <View style={[StyleApps.AppSection, Styleutility.mt8, Styleutility.w100]}>
                      <View style={StyleApps.AppList}>
                        <FlatList
                          data={artistList}
                          renderItem={({ item, index }) => (
                            <View style={[StyleApps.UserProfile, StyleApps.ArtistItem]}>
                              <View style={[StyleApps.AppItemMedia, StyleApps.ProfileMedia]}>
                                <Image source={item.profile_image || ImgProfile1} style={[StyleApps.AppItemImg, StyleApps.ProfileImg]} />
                              </View>
                              <View style={StyleApps.ProfileInfo}>
                                <Text numberOfLines={1} style={[Styleutility.text14, Styleutility.mr16]}>
                                  {item.artist_name}
                                </Text>
                              </View>
                              <View>
                                <div className="react-radio">
                                  <input
                                    type="radio"
                                    id={index}
                                    name="selectedArtist"
                                    checked={selectedArtist?.uniquelink === item.uniquelink}
                                    onChange={() => {
                                      setSelectedArtist(item);
                                    }}
                                  />
                                  <label htmlFor={index}></label>
                                </div>
                              </View>
                            </View>
                          )}
                        />
                      </View>
                    </View>
                  </ScrollView>
                  <View style={StyleApps.footer}>
                    <View style={StyleApps.footerContent}>
                      <View style={[Styleutility.mt16]}>
                        <TouchableOpacity
                          style={StyleBtn.BtnPrimaryGradient}
                          onPress={() => {
                            setFieldValue('fclid', selectedArtist);
                            setFieldValue('showArtistSelection', false);
                          }}
                        >
                          <Text style={[StyleBtn.BtnPrimaryGradientText]}>Apply</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </LinearGradient>
              )}

              <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                <View style={StyleApps.modalMainView}>
                  <View style={StyleApps.CraftsmodalWrapper}>
                    <View style={[StyleApps.ModalBottomContent, StyleApps.ModalHauto]}>
                      <View style={[StyleApps.ModalBottomHeader]}>
                        <Text style={StyleApps.AppSectionHeading}>Select Crafts</Text>
                        <TouchableOpacity style={[StyleApps.CloseModal, StyleApps.CloseBottomModal]} onPress={() => setModalVisible(false)}>
                          <Text>
                            <IconCross />
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={StyleApps.ModalBottomBody}>
                        <FlatList
                          data={craftList}
                          renderItem={({ item, index }) => (
                            <View style={StyleApps.ModalListItem}>
                              <div className="react-checkbox">
                                <input
                                  type="checkbox"
                                  id={index}
                                  checked={selectedCraftList.find((x) => x === item) ? true : false}
                                  onChange={() => {
                                    let find = selectedCraftList.find((x) => x === item);
                                    let tempData = [...selectedCraftList];
                                    if (find) {
                                      tempData = tempData.filter((x) => x !== item);
                                    } else {
                                      tempData.push(item);
                                    }
                                    setSelectedCraftList(tempData);
                                  }}
                                />
                                <label htmlFor={index}>{item}</label>
                              </div>
                            </View>
                          )}
                        />
                      </View>
                      <View style={[StyleApps.CraftssModalFooter, Styleutility.FlexBox]}>
                        <View style={[Styleutility.mt16, Styleutility.flexFill]}>
                          <TouchableOpacity
                            style={StyleBtn.BtnOutlineLight}
                            onPress={() => {
                              setFieldValue('craft', []);
                              setModalVisible(false);
                            }}
                          >
                            <Text style={[StyleBtn.BtnOutlineLightText]}>Clear All</Text>
                          </TouchableOpacity>
                        </View>
                        <View style={[Styleutility.mt16, Styleutility.flexFill, Styleutility.ml16]}>
                          <TouchableOpacity
                            style={StyleBtn.BtnPrimaryGradient}
                            onPress={() => {
                              if (selectedCraftList.length) {
                                setFieldValue('craft', selectedCraftList);
                                setModalVisible(false);
                              }
                            }}
                          >
                            <Text style={[StyleBtn.BtnPrimaryGradientText]}>Apply</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </Modal>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Signup;

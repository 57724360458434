import { CLEAR_ARTIST, GET_ARTIST, SET_ARTIST } from './ArtistActions';

const initialState = {};

const ArtistReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ARTIST:
      return {
        ...payload,
      };

    case GET_ARTIST:
      return state;

    case CLEAR_ARTIST:
      return {};

    default:
      return state;
  }
};

export default ArtistReducer;

import React, { useEffect, useState } from 'react';
import { Image, ImageBackground, SafeAreaView, Text, TouchableOpacity, View } from 'react-native-web';
import LinearGradient from 'react-native-web-linear-gradient';
import { useDispatch, useSelector } from 'react-redux';
import StyleApps from '../../../assets/common-style/common.app';
import StyleDevice from '../../../assets/common-style/common.device';
import StyleLanding from '../../../assets/common-style/common.landing';
import StyleUtility from '../../../assets/common-style/common.utility';
import ImgBg from '../../../assets/img/landing-bg.png';
import ImgArtistLogo from '../../../assets/img/simon-johnston.svg';
import { IconAndroidDots, IconArrowUp, IconBounce, IconCross, IconiOSInstall, IconiOSPlus } from '../../../components/Icons';
import { getArtistId, md5 } from '../../../helpers/helper';
import { setArtist } from '../../../redux/artist/ArtistActions';
import { analyticsInfo, artistDetails, artistDetailsAfterLogin } from '../../../services/apiEndpoint';
import { browserName, browserVersion, isAndroid, isChrome, isEdge, isIOS, isMobile, isSafari, isWinPhone, mobileModel, mobileVendor, osName, osVersion } from 'react-device-detect';
import { isNull } from 'lodash';
import AppLoader from '../../../components/AppLoader';
import config from '../../../config';
import Hammer from 'react-hammerjs';
import { isEmpty } from 'lodash';
import history from '../../../helpers/history';
import { get } from 'lodash';
import Styleutility from '../../../assets/common-style/common.utility';

const Android = ({ artist, hideDownloadSection, setHideDownloadSection, artistImageWidth, artistImageHeight }) => {
  return (
    <>
      <SafeAreaView style={[StyleApps.SafeAreaViewDevice]}>
        <View style={[StyleApps.root]}>
          <ImageBackground source={artist.mobile_background_image} style={StyleLanding.bgImage} resizeMode="contain">
            {/* <LinearGradient useAngle angle={180} colors={['var(--app-bg-color) 100%', 'var(--app-bg-color) 100%']} style={StyleLanding.linearGradient}> */}
            <View style={[StyleDevice.DeviceInstallInfo]}>
              <Text style={[StyleDevice.DeviceInstallInfoText]}>
                Install {artist.artist_name}'s app by clicking{' '}
                {/* <TouchableOpacity onPress={() => window.open(`${config.web.URL}/apps`, '_blank')} style={[StyleDevice.DeviceInstallInfoLink]}> */}
                here <IconAndroidDots fill="var(--color-white)" /> {/* </TouchableOpacity>{' '} */}
                and pressing "Add To Home Screen"
              </Text>
            </View>
            <View style={[StyleDevice.DeviceInstallIcon, StyleDevice.DeviceInstallIconAndoid]}>
              <IconBounce fill="var(--color-white)" />
            </View>
            <View style={[StyleApps.ArtistLogo, StyleUtility.mt22]}>
              <Image source={artist.artist_logo} style={[StyleApps.ArtistLogoImg, { width: artistImageWidth, height: artistImageHeight }]} />
            </View>
            <View style={[StyleDevice.DeviceStyle, StyleDevice.DeviceAndroid]}>
              <View style={[StyleDevice.DeviceContent]}>
                <View style={[StyleDevice.DeviceContentOverlay]}>
                  <Text style={StyleDevice.DeviceHeading}>{artist.artist_name}</Text>
                  <Text style={[StyleDevice.DeviceSubHeading]}>would like to share their content with you</Text>
                </View>
              </View>
            </View>
            {/* StickNote Android */}
            {hideDownloadSection && (
              <View style={[StyleDevice.StickNote, StyleDevice.StickNoteAndroid]}>
                <TouchableOpacity>
                  <Text style={[StyleDevice.StickNoteText]}>
                    If you’d like instant access to all of this artist's content, just tap the <IconAndroidDots fill="var(--color-white)" /> button and then press the 'Install
                    App'.
                  </Text>
                  <TouchableOpacity
                    style={[StyleDevice.StickNoteClose]}
                    onPress={() => {
                      setHideDownloadSection();
                    }}
                  >
                    <IconCross fill="var(--color-white)" />
                  </TouchableOpacity>
                </TouchableOpacity>
              </View>
            )}
            {/* </LinearGradient> */}
          </ImageBackground>
        </View>
      </SafeAreaView>
    </>
  );
};
const IosSafari = ({ artist, hideDownloadSection, setHideDownloadSection, artistImageWidth, artistImageHeight }) => {
  return (
    <SafeAreaView style={[StyleApps.SafeAreaViewDevice]}>
      <View style={[StyleApps.root]}>
        <ImageBackground source={artist.mobile_background_image} style={StyleLanding.bgImage} resizeMode="contain">
          {/* <LinearGradient useAngle angle={180} colors={['var(--app-bg-color) 100%', 'var(--app-bg-color) 100%']} style={StyleLanding.linearGradient}> */}
          {/* StickNote Ios */}
          {hideDownloadSection && isIOS && (
            <TouchableOpacity style={[StyleDevice.zIndexLg]}>
              <View style={[StyleDevice.StickNote]}>
                <Text style={[StyleDevice.StickNoteText]}>
                  If you'd like instant access to all of this artist's content, just tap the <IconiOSInstall fill="var(--color-white)" /> button at the bottom of your phone and
                  then press the <IconiOSPlus fill="var(--color-white)" /> button to add to your home screen
                </Text>
                <TouchableOpacity style={[StyleDevice.StickNoteClose]} onPress={setHideDownloadSection}>
                  <IconCross fill="var(--color-white)" />
                </TouchableOpacity>
              </View>
            </TouchableOpacity>
          )}
          <View style={[StyleApps.ArtistLogo, StyleUtility.mt90]}>
            <Image source={artist.artist_logo} style={[StyleApps.ArtistLogoImg, { width: artistImageWidth, height: artistImageHeight }]} />
          </View>
          <View style={[StyleDevice.DeviceStyle, StyleDevice.DeviceiOS]}>
            <View style={[StyleDevice.DeviceContent]}>
              <View style={[StyleDevice.DeviceContentOverlay]}>
                <Text style={StyleDevice.DeviceHeading}>{artist.artist_name}</Text>
                <Text style={StyleDevice.DeviceSubHeading}>would like to share their content with you</Text>
              </View>
            </View>
          </View>

          <View style={[StyleDevice.DeviceInstallIcon]}>
            <View style={[StyleDevice.DeviceInstallInfo, StyleUtility.mb16, StyleUtility.mt16]}>
              <Text style={[StyleDevice.DeviceInstallInfoText]}>
                Install {artist.artist_name}'s app by clicking here <IconiOSInstall fill="var(--color-white)" /> and pressing "Add To Home Screen"
              </Text>
            </View>
            <View style={[StyleDevice.DeviceInstallIconiOs]}>
              <IconBounce fill="var(--color-white)" />
            </View>
          </View>
          {/* </LinearGradient> */}
        </ImageBackground>
      </View>
    </SafeAreaView>
  );
};
const IosChrome = ({ artist, artistImageWidth, artistImageHeight }) => {
  return (
    <SafeAreaView style={[StyleApps.SafeAreaViewDevice]}>
      <View style={[StyleApps.root]}>
        <ImageBackground source={artist.mobile_background_image} style={StyleLanding.bgImage} resizeMode="contain">
          {/* <LinearGradient useAngle angle={180} colors={['var(--app-bg-color) 100%', 'var(--app-bg-color) 100%']} style={StyleLanding.linearGradient}> */}
          <View style={[StyleDevice.DeviceStyle, StyleDevice.DeviceiOS]}>
            <View style={[StyleDevice.DeviceContent]}>
              <View style={[StyleDevice.DeviceContentOverlay]}>
                <Text style={StyleDevice.DeviceHeading}>{artist.artist_name}</Text>
              </View>
            </View>
            <View style={[StyleApps.ArtistLogo]}>
              <Image source={artist.artist_logo} style={[StyleApps.ArtistLogoImg, { width: artistImageWidth, height: artistImageHeight }]} />
            </View>
            <View style={[StyleDevice.DeviceContent]}>
              <View style={[StyleDevice.DeviceContentOverlay]}>
                <Text style={[StyleDevice.DeviceSubHeading]}>Safari is required to install this app. Please open this page in Safari to continue.</Text>
              </View>
            </View>
          </View>
          {/* </LinearGradient> */}
        </ImageBackground>
      </View>
    </SafeAreaView>
  );
};
const WindowsPhone = ({ artist, artistImageWidth, artistImageHeight }) => {
  return (
    <SafeAreaView style={[StyleApps.SafeAreaViewDevice]}>
      <View style={[StyleApps.root]}>
        <ImageBackground source={artist.mobile_background_image} style={StyleLanding.bgImage} resizeMode="contain">
          {/* <LinearGradient useAngle angle={180} colors={['var(--app-bg-color) 100%', 'var(--app-bg-color) 100%']} style={StyleLanding.linearGradient}> */}
          <View style={[StyleApps.ArtistLogo, StyleUtility.mt90]}>
            <Image source={artist.artist_logo} style={[StyleApps.ArtistLogoImg, { width: artistImageWidth, height: artistImageHeight }]} />
          </View>
          <View style={[StyleDevice.DeviceStyle, StyleDevice.DeviceiOS]}>
            <View style={[StyleDevice.DeviceContent]}>
              <View style={[StyleDevice.DeviceContentOverlay]}>
                <Text style={StyleDevice.DeviceHeading}>{artist.artist_name}</Text>
                <Text style={[StyleDevice.DeviceSubHeading]}>
                  Tap the menu button(it can be found on the bottom right hand corner of your browser) and select 'pin to start' to install the app
                </Text>
              </View>
            </View>
          </View>
          {/* </LinearGradient> */}
        </ImageBackground>
      </View>
    </SafeAreaView>
  );
};
const Desktop = ({ artist, artistImageWidth, artistImageHeight }) => {
  return (
    <SafeAreaView style={[StyleApps.SafeAreaViewDevice]}>
      <View style={[StyleApps.root]}>
        <ImageBackground source={artist.mobile_background_image} style={StyleLanding.bgImage} resizeMode="contain">
          {/* <LinearGradient useAngle angle={180} colors={['var(--app-bg-color) 100%', 'var(--app-bg-color) 100%']} style={StyleLanding.linearGradient}> */}
          <View style={[StyleApps.ArtistLogo, StyleDevice.ArtistLogoDesktop]}>
            <Image source={artist.artist_logo} style={[StyleApps.ArtistLogoImg, { width: artistImageWidth, height: artistImageHeight }]} />
          </View>
          <View style={[StyleDevice.DeviceStyle, StyleDevice.DeviceStyleDesktop]}>
            <View style={[StyleDevice.DeviceContent]}>
              <View style={[StyleDevice.DeviceContentOverlay]}>
                <Text style={[StyleDevice.DeviceHeading, StyleDevice.DeviceHeadingLg]}>{artist.artist_name}</Text>
                <Text style={[StyleDevice.DeviceSubHeading]}>
                  <TouchableOpacity
                    onPress={() => {
                      window.open(`https://${getArtistId()}.fenix.band/artist/welcome`, '_blank');
                    }}
                  >
                    {' '}
                    <Text>Please click here to view the web app</Text>
                  </TouchableOpacity>
                </Text>
              </View>
            </View>
          </View>
          {/* <View style={[StyleDevice.DeviceInstallInfo, StyleDevice.DeviceInstallInfoDekstop]}>
            <Text style={[StyleDevice.DeviceInstallInfoText]} onPress={() => window.open(`${config.web.URL}/apps`, '_blank')}>
              See more apps by clicking <TouchableOpacity style={[StyleDevice.DeviceInstallInfoLink]}>here</TouchableOpacity>.
            </Text>
          </View> */}
          {/* </LinearGradient> */}
        </ImageBackground>
      </View>
    </SafeAreaView>
  );
};
const LandingPage = () => {
  const dispatch = useDispatch();
  const artist = useSelector((state) => state.artist);
  const { user } = useSelector((state) => state.user);

  const [artistImageWidth, setArtistImageWidth] = useState(0);
  const [artistImageHeight, setArtistImageHeight] = useState(0);
  const [hideDownloadSection, setHideDownloadSection] = useState(true);
  const [ready, setready] = useState(false);
  useEffect(() => {
    getArtistDetails();
    setready(true);
  }, []);

  const getArtistDetails = async () => {
    if (localStorage.getItem('ARTPWATKEN')) {
      await artistDetailsAfterLogin(getArtistId())
        .then((res) => {
          setRequiredData(res);
        })
        .catch((err) => {
          alert(err.message.toString());
        });
    } else {
      await artistDetails(getArtistId())
        .then((res) => {
          setRequiredData(res);
        })
        .catch((err) => {
          alert(err.message.toString());
        });
    }
  };

  const setRequiredData = (res) => {
    localStorage.setItem('arkey', res.data.unique_id);
    dispatch(setArtist(res.data));
    setAnalyticsInfo();
    // document.querySelector("meta[name='theme-color']").setAttribute('content', get(res, 'data.app_bg_color'));

    document.documentElement.style.setProperty('--app-bg-color-or', get(res, 'data.app_bg_color'));
    document.documentElement.style.setProperty('--app-bg-color', get(res, 'data.app_bg_color') + 'bf');
    document.documentElement.style.setProperty('--theme-color', get(res, 'data.theme_color'));
    document.documentElement.style.setProperty('--icon-color', get(res, 'data.theme_color'));
    document.documentElement.style.setProperty('--text-color', get(res, 'data.common_text_color'));
    document.documentElement.style.setProperty('--sub-text-color', get(res, 'data.common_sub_text_color'));
    document.documentElement.style.setProperty('--gradient-startcolor', '#8859F9');
    document.documentElement.style.setProperty('--gradient-endcolor', '#09D1D0');
    // document.documentElement.style.setProperty('--gradient-startcolor', get(res, 'data.app_bg_color') + 'a6'); /* a6 = opacity 0.65 */
    // document.documentElement.style.setProperty('--gradient-endcolor', get(res, 'data.app_bg_color'));
    document.documentElement.style.setProperty('--divider-color', get(res, 'data.theme_color') + '4d'); /* a4d6 = opacity 0.3 */
    document.documentElement.style.setProperty('--button-text-color', get(res, 'data.common_text_color'));
    document.documentElement.style.setProperty('--button-border-color', get(res, 'data.theme_color'));
    document.documentElement.style.setProperty('--player-gradient-startcolor', get(res, 'data.app_bg_color') + 'a6'); /* a6 = opacity 0.65 */
    document.documentElement.style.setProperty('--player-gradient-endcolor', get(res, 'data.app_bg_color'));
    document.documentElement.style.setProperty('--player-card-shadow', get(res, 'data.theme_color') + 'bf'); /* bf = opacity 0.75 */

    Image.getSize(res.data.artist_logo, (width, height) => {
      setArtistImageWidth(width);
      setArtistImageHeight(height);
    });
  };
  const setAnalyticsInfo = async () => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      const artist_id = localStorage.getItem('arkey');
      if (!localStorage.getItem('_utTrack')) {
        const visitor_id = md5(Date.now().toString());
        const payload = {
          visitor_id: visitor_id.toUpperCase(),
          browser_version: browserVersion,
          browser_name: browserName,
          mobile_vendor: mobileVendor,
          mobile_model: mobileModel,
          os_name: osName,
          os_version: osVersion,
        };

        await analyticsInfo(payload)
          .then((res) => {
            localStorage.setItem('_utTrack', visitor_id.toString());
          })
          .catch((err) => {
            console.error('err', err.message);
          });
      }
    }
  };

  if (isEmpty(artist) || !ready) {
    return <AppLoader title={'App is Loading...'} />;
  }
  return (
    <>
      <div className="standalonemode">
        {isMobile ? (
          <>
            {isAndroid && (
              <Android
                artistImageWidth={artistImageWidth}
                artistImageHeight={artistImageHeight}
                artist={artist}
                hideDownloadSection={hideDownloadSection}
                setHideDownloadSection={() => {
                  setHideDownloadSection(false);
                }}
              />
            )}
            {isIOS && isSafari && (
              <IosSafari
                artistImageWidth={artistImageWidth}
                artistImageHeight={artistImageHeight}
                artist={artist}
                hideDownloadSection={hideDownloadSection}
                setHideDownloadSection={() => {
                  setHideDownloadSection(false);
                }}
              />
            )}
            {isIOS && isChrome && <IosChrome artistImageWidth={artistImageWidth} artistImageHeight={artistImageHeight} artist={artist} />}
            {isWinPhone && isEdge && <WindowsPhone artistImageWidth={artistImageWidth} artistImageHeight={artistImageHeight} artist={artist} />}
          </>
        ) : (
          <Desktop artistImageWidth={artistImageWidth} artistImageHeight={artistImageHeight} artist={artist} />
        )}
      </div>

      <View style={[StyleApps.root, StyleApps.standalonemode]}>
        <Hammer direction={'DIRECTION_ALL'} onSwipeUp={() => history.push('/artist/dashboard')}>
          <ImageBackground source={artist.mobile_background_image} style={StyleLanding.bgImage} resizeMode="contain">
            {/* <LinearGradient useAngle angle={180} colors={['rgba(0, 0, 0, 0.0001) 67.14%', '#000000 100%']} style={StyleLanding.linearGradient}> */}
            {/* <LinearGradient useAngle angle={180} colors={['var(--app-bg-color)', 'var(--app-bg-color)']} style={StyleLanding.linearGradient}> */}
            {/* <View style={StyleApps.header}>
              <View style={StyleApps.headerContent}>
                <Text style={StyleApps.logo}>
                  <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 24H4.54445V14.1847H14.2549V10.1993H4.54445V3.95038H15.6518V0H0L0 24Z" fill="var(--icon-color)" />
                  </svg>
                </Text>
                {/* <Text style={StyleApps.pagetitle}>Title</Text> 
              </View>
            </View> */}

            <View style={isMobile ? [StyleApps.header, StyleApps.headerdark] : [StyleApps.header]}>
              <View style={[StyleApps.headerContent, Styleutility.pr20]}>
                <Text style={StyleApps.logo}>
                  <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 24H4.54445V14.1847H14.2549V10.1993H4.54445V3.95038H15.6518V0H0L0 24Z" fill="white" />
                  </svg>
                </Text>

                {isMobile && (!localStorage.getItem('ARTPWATKEN') || isEmpty(user)) && (
                  <View style={[Styleutility.dFlex, Styleutility.mlauto]}>
                    <TouchableOpacity onPress={() => history.push('/login')}>
                      <Text style={[StyleApps.Text16]}>LOGIN</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[Styleutility.ml24]} onPress={() => history.push('/signup')}>
                      <Text style={[StyleApps.Text16]}>SIGNUP</Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>

            <View style={StyleDevice.ArtistLogoCenterY}>
              <View style={StyleApps.ArtistLogo}>
                <Image source={artist.artist_logo} style={[StyleApps.ArtistLogoImg, { width: artistImageWidth, height: artistImageHeight }]} />
              </View>
            </View>
            {!isEmpty(artist) && (
              <TouchableOpacity style={StyleLanding.appFooter}>
                <IconArrowUp className="text-swipe" />
                <Text style={StyleLanding.swipeText}>
                  <span className="text-swipe">Swipe up for more</span>
                </Text>
              </TouchableOpacity>
            )}
            {/* </LinearGradient> */}
          </ImageBackground>
        </Hammer>
      </View>
    </>
  );
};

export default LandingPage;

export const SET_SONG = 'SET_SONG';
export const SET_PLAYLIST = 'SET_PLAYLIST';

// Action Creators
export const setPlaylist = (songs, source) => {
  return {
    type: SET_PLAYLIST,
    payload: {
      playlist: songs,
      playlistSource: source,
    },
  };
};

/* ACTIONS */

export const GET_CURRENCY = 'GET_CURRENCY';
export const SET_CURRENCY = 'SET_CURRENCY';

/* ACTION CREATORS */

export const setCurrency = (artist) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENCY,
      payload: artist,
    });
  };
};

export const getCurrency = () => {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENCY,
      payload: {},
    });
  };
};

import { getArtistId } from '../../helpers/helper';
import history from '../../helpers/history';
import { artistDetailsAfterLogin, getLoggedInUserProfile, login } from '../../services/apiEndpoint';
import { setArtist } from '../artist/ArtistActions';
import { hideLoading, showLoading } from '../loader/LoaderActions';
import { showNotif } from '../notification/NotificationActions';

/* ACTIONS */
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const USER = 'USER';
export const UPDATE_USER = 'UPDATE_USER';

/* LOGIN */
export const loginAction = (payload) => {
  return (dispatch) => {
    dispatch(showLoading());
    login(payload)
      .then(async ({ access_token, data }) => {
        localStorage.setItem('ARTPWATKEN', access_token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            isLoggedIn: true,
            user: data,
            message: 'logged in successfully',
          },
        });

        await artistDetailsAfterLogin(getArtistId())
          .then((res) => {
            dispatch(setArtist(res.data));
            history.push('/artist/dashboard');
          })
          .catch((err) => {
            dispatch(showNotif(err.message.toString()));
          });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            isLoggedIn: false,
            user: {},
            message: err.message,
          },
        });
        console.log('errr', err);

        dispatch(showNotif(err.message.toString()));
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
};

/* GET REGISTERED LOGGED IN USER */
export const getLoggedInUser = () => {
  return (dispatch) => {
    getLoggedInUserProfile()
      .then((res) => {
        dispatch({
          type: USER,
          payload: {
            user: res.data,
            type: res.data.type,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            user: {},
            type: undefined,
            isLoggedIn: false,
            message: err.message,
          },
        });
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
};

export const getMe = (res) => {
  return {
    type: USER,
    payload: {
      user: res.data,
      type: res.data.type,
    },
  };
};

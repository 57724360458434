import { DELETE, GET, POST, PUT } from './rest.service';

export const getUSDToFenixPrice = () => {
  return GET(`/currency/usdtofnx`);
};
export const artistDetails = (id) => {
  return GET(`/v2/artist/get/${id}`);
};

export const artistDetailsAfterLogin = (id) => {
  return GET(`/v2/user/artist/info/${id}`);
};
export const artistLinks = () => {
  return GET(`/v2/artist/app/links`);
};
export const analyticsInfo = (payload) => {
  return POST(`/v2/artist/app/analytics/track`, payload);
};

export const getAllSongListByArtist = (payload) => {
  return GET(`/v2/artist/app/song/list`, payload);
};

export const getDownloadNumber = (id) => {
  return GET(`/v2/artist/app/install`);
};

export const getAllAlbumListByArtist = (payload) => {
  return GET(`/v2/artist/app/album/list`, payload);
};
export const getAlbumDetailByArtist = (id) => {
  return GET(`/v2/artist/app/album/get/${id}`);
};
export const getAllSocialFeedListByArtist = (payload) => {
  return GET(`/v2/artist/app/social/feed`, payload);
};
export const getAllNFTListByArtist = (payload) => {
  return GET(`/v2/artist/app/nft/list`, payload);
};
export const getNFTDetailByArtist = (id) => {
  return GET(`/v2/artist/app/nft/get/${id}`);
};
export const getAllProductListByArtist = (payload) => {
  return GET(`/v2/artist/app/product/list`, payload);
};
export const getProductDetailById = (id) => {
  return GET(`/v2/artist/app/product/get/${id}`);
};

export const getAllVideoListByArtist = (payload) => {
  return GET(`/v2/artist/app/video/list`, payload);
};

export const getAllPhotoListByArtist = (payload) => {
  return GET(`/v2/artist/app/photo/list`, payload);
};
export const getPhotoDetailByArtist = (id) => {
  return GET(`/v2/artist/app/photo/get/${id}`);
};
export const getVideoetailByArtist = (id) => {
  return GET(`/v2/artist/app/video/get/${id}`);
};
export const getAllGigListByArtist = (payload) => {
  return GET(`/v2/artist/app/upcoming-gigs/list`, payload);
};
export const getGigDetailByArtist = (id) => {
  return GET(`/v2/artist/app/upcoming-gigs/get/${id}`);
};
export const getCategoriesBySlug = (slug, payload) => {
  return GET(`/v2/artist/app/categories/list/${slug}`, payload);
};
export const getCategorieById = (id) => {
  return GET(`/v2/artist/app/categories/get/${id}`);
};

export const getCommentList = (id, type, payload) => {
  return GET(`/v2/artist/app/comments/${id}/${type}`, payload);
};

export const getFollowerCount = (id, payload) => {
  return GET(`/v2/artist/app/following/${id}`, payload);
};

export const getFollowingCount = (id, payload) => {
  return GET(`/v2/artist/app/followers/${id}`, payload);
};

// Auth

export const login = (payload) => {
  return POST(`/v2/user/login`, payload);
};
export const signUp = (payload) => {
  return POST(`/v2/user/register`, payload);
};
export const getLoggedInUserProfile = () => {
  return GET(`/v2/user/me`);
};
export const getCraftList = () => {
  return GET(`/craft.json`);
};
export const getSupportArtistList = (payload) => {
  return POST(`/search_artist`, payload);
};

export const followAritst = (id, payload) => {
  return PUT(`/v2/user/follow/${id}`, payload);
};
export const unFollowAritst = (id, payload) => {
  return PUT(`/v2/user/unfollow/${id}`, payload);
};

export const sendFriendRequest = (payload) => {
  return POST(`/v2/user/friend-request/send`, payload);
};
export const acceptFriendRequest = (id, toId) => {
  return PUT(`/v2/user/friend-request/accept/${id}/${toId}`);
};
export const cancelFriendRequest = (id, toId) => {
  return PUT(`/v2/user/friend-request/cancel/${id}/${toId}`);
};

export const unFriendRequest = (id) => {
  return PUT(`/v2/user/friends/unfriend/${id}`);
};

export const subscribeMailingList = (payload) => {
  return POST(`/v2/user/mailing-list/subscribe`, payload);
};

export const unsubscribeMailingList = (id) => {
  return DELETE(`/v2/user/mailing-list/unsubscribe/${id}`);
};
export const supportArtist = (payload) => {
  return PUT(`/v2/user/support_artist`, payload);
};

export const likeApi = (type, payload) => {
  return POST(`/v2/user/${type}/like`, payload);
};

export const unlikeApi = (type, payload) => {
  return POST(`/v2/user/${type}/unlike`, payload);
};

export const searchUser = (payload) => {
  return GET(`/v2/user/friends/search`, payload);
};
export const addComment = (payload) => {
  return POST(`/v2/user/comments`, payload);
};

export const mediaCounter = (type, payload) => {
  return POST(`/v2/user/${type || 'video'}/counter`, payload);
};

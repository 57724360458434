import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';

const SnackBarWrapper = (props) => {
  const handleClose = () => {
    props.onClose();
  };

  const { message, action, display, duration } = props;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={display}
      autoHideDuration={duration}
      onClose={props.onClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{message}</span>}
      action={[
        <Button key="undo" color="secondary" size="small" onClick={handleClose}>
          {action}
        </Button>,
      ]}
    />
  );
};

export default SnackBarWrapper;

import React from 'react';

const AppLoader = (props) => (
  <div className="loading-overlay">
    <div className="spinner mb-3">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
    <h2>{props.title}</h2>
  </div>
);

export default AppLoader;

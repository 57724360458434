// Actions
export const SHOW_NOTIF = 'SHOW_NOTIF';
export const HIDE_NOTIF = 'HIDE_NOTIF';

// Action Creators
export const showNotif = (title, buttonText = 'OKAY', duration = 4000, display = true) => {
  return {
    type: SHOW_NOTIF,
    payload: {
      message: title,
      action: buttonText,
      duration: duration,
      display,
    },
  };
};

import { transform } from 'lodash';
import { StyleSheet } from 'react-native';
import { baseFontFamily } from './common.app';

export default StyleSheet.create({
  mt0: {
    marginTop: 0,
  },
  mb0: {
    marginBottom: 0,
  },
  pt0: {
    paddingTop: 0,
  },
  pb0: {
    paddingBottom: 0,
  },
  py0: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  pt10: {
    paddingTop: 10,
  },
  ml4: {
    marginLeft: 4,
  },
  mb4: {
    marginBottom: 4,
  },
  mb8: {
    marginBottom: 8,
  },
  mt4: {
    marginTop: 4,
  },
  mt8: {
    marginTop: 8,
  },
  mt10: {
    marginTop: 10,
  },
  pb8: {
    paddingBottom: 8,
  },
  ml6: {
    marginLeft: 6,
  },
  ml8: {
    marginLeft: 8,
  },
  ml10: {
    marginLeft: 10,
  },
  ml16: {
    marginLeft: 16,
  },
  ml24: {
    marginLeft: 24,
  },
  mr16: {
    marginRight: 16,
  },
  mr8: {
    marginRight: 8,
  },
  mx0: {
    marginLeft: 0,
    marginRight: 0,
  },
  mx5: {
    marginLeft: 5,
    marginRight: 5,
  },
  mb10: {
    marginBottom: 10,
  },
  mb16: {
    marginBottom: 16,
  },
  pb16: {
    paddingBottom: 16,
  },
  my16: {
    marginTop: 16,
    marginBottom: 16,
  },
  my24: {
    marginTop: 24,
    marginBottom: 24,
  },
  py8: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  pb10: {
    paddingBottom: 10,
  },
  mb24: {
    marginBottom: 24,
  },
  mb32: {
    marginBottom: 32,
  },
  mt16: {
    marginTop: 16,
  },
  mt22: {
    marginTop: 22,
  },
  mt90: {
    marginTop: 90,
  },
  mr0: {
    marginRight: 0,
  },
  pr20: {
    paddingRight: 20,
  },
  p0: {
    padding: 0,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderColor: 'var(--divider-color)',
  },
  borderBottom0: {
    borderBottomWidth: 0,
  },
  FontNormal: {
    fontWeight: 400,
  },
  FontSemibold: {
    fontWeight: 600,
  },
  FontMedium: {
    fontWeight: 500,
  },
  dFlex: {
    flexDirection: 'row',
  },
  dFlexColumn: {
    flexDirection: 'column',
  },
  FlexEnd: {
    alignSelf: 'flex-end',
  },
  mlauto: {
    marginLeft: 'auto',
  },
  mxauto: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  flexFill: {
    flex: '1',
  },
  flexChatMsg: {
    flex: '0 auto',
  },

  togglefullwidth: {
    position: 'absolute',
    width: '100%',
    alignItems: 'flex-end',
    zIndex: '2',
  },
  FlexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FlexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  FlexBoxC: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  JustifyCenter: {
    justifyContent: 'center',
  },
  FlexStart: {
    alignItems: 'flex-start',
  },
  FlexCenter: {
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  w100: {
    width: '100%',
  },
  w50: {
    width: '50%',
  },
  textdark: {
    color: 'var(--color-dark)',
  },
  TextLink: {
    textDecorationLine: 'underline',
  },
  Text24: {
    ...baseFontFamily,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 29,
    color: 'var(--primary-white)',
    marginBottom: 10,
  },
  Text22: {
    ...baseFontFamily,
    marginTop: 16,
    fontWeight: 500,
    fontSize: 22,
    lineHeight: 27,
    color: 'var(--primary-white)',
  },
  text14: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 17,
    color: 'var(--primary-white)',
  },
  Heading16: {
    ...baseFontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: 'var(--primary-white)',
  },
  lhNormal: {
    lineHeight: 'normal',
  },
  text12: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 18,
    color: 'var(--primary-white)',
  },
  textplaceholder: {
    opacity: '0.5',
  },
  lh20: {
    lineHeight: 20,
  },
  textCap: {
    ...baseFontFamily,
    textTransform: 'capitalize',
    fontWeight: '600',
  },
  BorderNone: {
    border: 'none',
  },
  RadiusRounded: {
    borderRadius: '50%',
    overflow: 'hidden',
  },
  h100: {
    height: '100%',
  },
  ContentFlexEnd: {
    justifyContent: 'flex-end',
  },
  ContentBetween: {
    justifyContent: 'space-between',
  },
  shadowDark: {
    boxShadow: '3px 3px 15px rgba(255, 255, 255, 0.1)',
  },
  mw270: {
    maxWidth: 270,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  textLight: {
    color: '#ffffff',
    opacity: 0.6,
  },
  textWhite: {
    color: 'var(--primary-white)',
  },
  inputCode: {
    ...baseFontFamily,
    fontSize: 10.5,
    color: '#ffffff',
    opacity: 0.5,
    fontWeight: 500,
    marginBottom: 8,
  },
  TextThemeLight: {
    color: 'var(--primary-white)',
    opacity: 0.5,
  },

  FlexBoxAround: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  BgWhite: {
    backgroundColor: '#ffffff',
  },
  FlexRow: {
    flexDirection: 'row',
  },
  textPrimary: {
    color: 'var(--primary-purple)',
  },
  textSecondaryGray: {
    color: 'var(--secondary-gray)',
  },
  TextLightGray: {
    color: 'var(--secondary-light-gray)',
  },

  text12w500: {
    ...baseFontFamily,
    fontSize: 12,
    fontWeight: 500,
  },
  text12w600: {
    ...baseFontFamily,
    fontSize: 12,
    fontWeight: 600,
  },
  text12w400: {
    ...baseFontFamily,
    fontSize: 12,
    fontWeight: 400,
  },
  text14w500: {
    ...baseFontFamily,
    fontSize: 14,
    fontWeight: 500,
  },
  text18w400: {
    ...baseFontFamily,
    fontSize: 18,
    fontWeight: 400,
    color: 'var(--primary-white)',
  },

  text14w400: {
    ...baseFontFamily,
    fontSize: 14,
    fontWeight: 400,
  },
});

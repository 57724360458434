import { StyleSheet } from 'react-native';
import { isMobileOnly } from 'react-device-detect';
export const baseFontFamily = { fontFamily: 'var(--font-family)' };
export const AppPaddingX = { paddingLeft: 20, paddingRight: 20 };
export const AppPaddingL = { paddingLeft: 20, paddingRight: 0 };

export const BtnFenixGradient = {
  // backgroundImage: 'linear-gradient(to left, var(--btnfenix-gradient-startcolor), var(--btnfenix-gradient-endcolor))',
  // borderRadius: '50%',
  borderWidth: 2,
  borderColor: 'var(--button-border-color)',
};
export const BtnPlayGradient = {
  backgroundImage: 'linear-gradient(135deg, var(--btn-gradient-startcolor), var(--btn-gradient-endcolor))',
  borderRadius: '50%',
};
export const MusicActionGradient = {
  backgroundImage: 'linear-gradient(135deg, #A2D246 0%, #009BAD 100%)',
  borderRadius: '50%',
  // borderWidth: 2,
  // borderColor: 'var(--button-border-color)',
};
export const BtnGradient = {
  // backgroundColor: 'transparent',
  borderWidth: 2,
  borderColor: 'var(--button-border-color)',
  // borderImageSource: 'linear-gradient(to left, var(--btn-gradient-startcolor), var(--btn-gradient-endcolor))',
  // borderImageSlice: '1',
  // backdropFilter: 'blur(27.1828px)',
};

export const PrimaryGradient = {
  backgroundImage: 'linear-gradient(135deg, var(--btn-gradient-startcolor), var(--btn-gradient-endcolor))',
};

export default StyleSheet.create({
  appMain: {
    padding: 10,
    borderWidth: 1,
    position: 'relative',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '100%',
    width: '100%',
    paddingTop: 50,
  },
  AppPaddingX: {
    ...AppPaddingX,
  },

  root: {
    ...baseFontFamily,
    height: '100vh',
    width: '100%',
    color: 'var(--text-color)',
    backgroundColor: 'var(--app-bg-color-or)',

    // flex: 1,
    // overflowY: 'scroll',
  },
  SafeAreaViewDevice: {
    zIndex: 1091,
  },
  standalonemode: {
    // display: 'none',
  },
  Dashboardroot: {
    backgroundColor: 'var(--primary-black)',
  },
  Playerroot: {
    backgroundColor: 'var(--app-bg-color)',
  },
  header: {
    height: 'var(--header-height)',
    display: 'flex',
    justifyContent: 'center',
  },
  headerdark: {
    backgroundColor: '#000000',
  },
  headerContent: {
    ...AppPaddingX,
    height: 'var(--header-height-content)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 50,
    paddingRight: 50,
  },
  footer: {
    // display: 'flex',
    // justifyContent: 'center',
  },
  buttonIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 40,
    height: 40,
    backgroundColor: 'var(--back-btn-bg)',
    borderRadius: '50%',
  },
  footerContent: {
    ...AppPaddingX,
    paddingTop: 16,
    paddingBottom: 16,
  },
  Chatfooter: {
    backgroundColor: '#29334F',
  },
  ChatUploads: {
    backgroundColor: '#222837',
  },
  scrollViewContentEnd: {
    flex: '0',
    height: '56.65vh',
    // justifyContent: 'end',
    // position: 'absolute',
    // bottom: 0,
  },
  linearGradientBottom: {
    height: '56vh',
  },
  Bottomoverlay: {
    height: '56.65vh',
    justifyContent: 'flex-end',
  },
  BottomoverlayContent: {
    ...AppPaddingX,
    paddingTop: 16,
    paddingBottom: 16,
    height: '100%',
    justifyContent: 'flex-end',
  },
  BottomoverlayText: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
  pagetitle: {
    ...baseFontFamily,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'center',
    letterSpacing: 0.923077,
    textTransform: 'uppercase',
    color: 'var(--primary-white)',
  },
  back: {
    // position: isMobileOnly ? 'absolute' : 'fixed',
    position: 'absolute',
    display: 'flex',
    left: 20,
    // top: isMobileOnly ? 0 : 20,
    top: 0,
    width: 40,
    height: 40,
    alignItems: 'center',
    backgroundColor: 'var(--back-btn-bg)',
    borderRadius: '50%',
    justifyContent: 'center',
  },
  headerIconEnd: {
    position: 'absolute',
    display: 'flex',
    right: 20,
    top: 20,
    width: 14,
    height: 24,
    alignItems: 'center',
  },
  logo: {
    position: isMobileOnly ? 'absolute' : 'fixed',
    display: 'flex',
    left: 20,
    top: isMobileOnly ? 0 : 20,
    width: 16,
    height: 24,
    alignItems: 'center',
  },
  SongArrow: {
    position: 'absolute',
    display: 'flex',
    left: 20,
    top: 8,
    width: 24,
    height: 24,
    alignItems: 'center',
  },
  ArtistLogo: {
    ...AppPaddingX,
    marginTop: 16,
    paddingBottom: 8,
  },
  ArtistLogoImg: {
    objectFit: 'contain',
    objectPosition: 'center',
    resizeMode: 'contain',
    maxHeight: 150,
    maxWidth: 'calc(100% - 40px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100%',
  },
  scrollView: {
    // backgroundColor: 'pink',
    // marginHorizontal: 20,
  },
  scrollViewComment: {
    // height: 350,
    maxHeight: '50%',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    overflowY: 'auto',
    paddingTop: 16,
    marginBottom: 8,
  },
  scrollViewNoteShow: {
    paddingBottom: 111,
  },
  scrollViewNoteShowLg: {
    paddingBottom: 127,
  },

  AppsInstall: {
    ...AppPaddingX,
    ...baseFontFamily,
    marginTop: 24,
    marginBottom: 24,
    textAlign: 'center',
  },
  AppsInstallCount: {
    color: 'var(--theme-color)',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 22,
    marginBottom: 6,
  },
  AppsInstallText: {
    color: 'var(--text-color)',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 15,
  },
  FriendsInfo: {
    ...AppPaddingX,
    ...baseFontFamily,
    marginTop: 24,
    marginBottom: 24,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  FriendsItem: {
    marginRight: 20,
    marginLeft: 20,
  },
  FriendsValue: {
    ...baseFontFamily,
    paddingBottom: 5,
    flex: 1,
    fontWeight: 600,
    fontSize: 12,
    color: 'var(--color-white)',
    textAlign: 'center',
  },
  FriendsText: {
    ...baseFontFamily,
    color: 'var(--secondary-gray)',
    fontWeight: 500,
    fontSize: 12,
    textAlign: 'center',
  },
  Divider: {
    marginLeft: 20,
    marginRight: 20,
    height: 1,
    backgroundColor: 'var(--secondary-border)',
    borderRadius: 5,
  },
  DividerMy: {
    marginTop: 8,
    marginBottom: 8,
  },
  ArtistAbout: {
    ...AppPaddingX,
    marginTop: 24,
    marginBottom: 24,
    textAlign: 'center',
  },
  ArtistLinks: {
    ...AppPaddingX,
    marginTop: 24,
    marginBottom: 24,
    textAlign: 'center',
  },
  StyleText: {
    ...baseFontFamily,
    fontSize: 14,
    fontWeight: 400,
    color: 'var(--secondary-light-gray)',
    marginBottom: 15,
  },
  StyleFBText: {
    ...baseFontFamily,
    fontSize: 15,
    lineHeight: 18,
    color: 'var(--text-color)',
    marginBottom: 15,
  },
  ArtistName: {
    ...baseFontFamily,
    fontSize: 24,
    fontWeight: 600,
    color: 'var(--sub-text-color)',
    // opacity: '0.7',
    textAlign: 'center',
  },
  AppSection: {
    marginTop: 24,
    marginBottom: 24,
  },

  AppSectionHeader: {
    ...AppPaddingX,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  AppSectionHeading: {
    ...baseFontFamily,
    fontWeight: 500,
    fontSize: 20,
    color: 'var(--primary-white)',
  },
  AppList: {
    ...AppPaddingX,
  },
  AppListHorizontal: {
    ...AppPaddingL,
    flexDirection: 'row',
  },
  AppListItem: {
    marginTop: 16,
    width: '100%',
  },
  AppListItemCenter: {
    width: 150,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  AppListItemHorizontal: {
    marginRight: 15,
    width: 150,
  },
  AppListItemHorizontalLarge: {
    marginRight: 9,
    width: 160,
  },
  AppItemMedia: {
    borderRadius: 15,
    overflow: 'hidden',
    marginBottom: 8,
  },
  AppItemImg: {
    resizeMode: 'cover',
    objectFit: 'cover',
    width: '100%',
    height: 150,
  },
  AppItemImgLg: {
    height: 180,
  },
  AppItemImgProduct: {
    resizeMode: 'contain',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  AppItemNFTMedia: {
    height: 100,
  },
  AppItemProductMedia: {
    width: 160,
    height: 180,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    overflow: 'hidden',
  },
  ProductImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    objectFit: 'contain',
    borderRadius: 15,
    overflow: 'hidden',
  },
  AppItemImgDetails: {
    height: 194,
  },
  SocialItem: {
    width: '100%',
  },
  SongsItem: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  SongsText: {
    marginLeft: 10,
    flex: 1,
  },
  SongsMedia: {
    width: 64,
    height: 64,
    marginBottom: 0,
  },
  SongsMediaImg: {
    resizeMode: 'cover',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  SocialMediaImg: {
    resizeMode: 'contain',
    objectFit: 'contain',
    width: '100%',
    // height: 220,
    backgroundColor: 'var(--media-bg-dark)',
  },
  AppItemNFTImg: {
    width: '100%',
    height: '100%',
    backgroundColor: 'var(--media-bg-dark)',
  },
  AppItemProductImg: {
    resizeMode: 'contain',
    objectFit: 'contain',
    // backgroundColor: 'var(--media-bg-light)',
    width: '100%',
    height: '100%',
  },
  AppListScrollX: {
    ...AppPaddingX,
  },
  AppItemHeading: {
    ...baseFontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: 'var(--primary-white)',
    marginBottom: 5,
  },
  AppItemHeadingLg: {
    fontSize: 18,
    lineHeight: 23,
    marginBottom: 8,
  },
  AppItemSubText: {
    ...baseFontFamily,
    fontWeight: 500,
    fontSize: 12,
    color: 'var(--secondary-gray)',
  },

  SocialMediaList: {
    // ...AppPaddingX,
    marginTop: 16,
    marginBottom: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  SocialMediaItem: {
    marginRight: 8,
    marginBottom: 4,
  },
  NFTFenix: {
    flexDirection: 'row',
  },
  TextFenix: {
    ...baseFontFamily,
    flex: 1,
    marginTop: 8,
    fontWeight: 600,
    fontSize: 12,
    textTransform: 'uppercase',
    color: 'var(--secondary-gray)',
  },
  AppListFull: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  AppListFullContent: {
    ...AppPaddingX,
    borderRadius: 0,
  },
  AppItemMediaFull: {
    borderRadius: 0,
  },
  AppItemMediaImgFull: {
    height: 245,
    borderRadius: 0,
    resizeMode: 'contain',
    objectFit: 'contain',
    backgroundColor: 'var(--media-bg-dark)',
  },
  DimensionViewImg: {
    // height: 245,
    borderRadius: 0,
    resizeMode: 'contain',
    objectFit: 'contain',
    backgroundColor: 'var(--media-bg-dark)',
  },
  searchBar: {
    ...AppPaddingX,
    position: 'relative',
  },
  searchControlIcon: {
    position: 'absolute',
    display: 'flex',
    left: 0,
    top: 0,
    width: 24,
    height: 24,
    alignItems: 'center',
  },
  searchCrossIcon: {
    position: 'absolute',
    display: 'flex',
    right: 0,
    top: 0,
    width: 18,
    height: 18,
    alignItems: 'center',
  },
  searchControl: {
    paddingTop: 3,
    paddingBottom: 10,
    paddingLeft: 32,
    borderBottomWidth: 1,
    borderColor: 'var(--secondary-border)',
  },
  InputLine: {
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderColor: 'var(--secondary-border)',
  },
  InputLineLight: {
    borderColor: 'var(--secondary-border)',
  },
  textinput: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 16,
    outlineColor: 0,
    outlineOffset: 0,
    outlineStyle: 'none',
    outlineWidth: 0,
    color: 'var(--text-color)',
  },
  inputPicker: {
    ...baseFontFamily,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 17,
    backgroundColor: 'transparent',
    color: 'var(--text-color)',
    outlineColor: 0,
    outlineOffset: 0,
    outlineStyle: 'none',
    outlineWidth: 0,
    border: 0,
  },

  inputUI: {
    color: '#ffffff',
  },
  ListRow: {
    // flexWrap: 'wrap',
    // flexDirection: 'row',
    paddingLeft: 12.5,
    paddingRight: 12.5,
  },
  ListRowWrap: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingLeft: 12.5,
    paddingRight: 12.5,
  },
  ListCol: {
    paddingLeft: 7.5,
    paddingRight: 7.5,
    width: '50%',
  },
  AlbumsButton: {
    ...baseFontFamily,
    ...AppPaddingX,
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 9,
  },
  ImgFluid: {
    width: '100%',
    height: '100%',
    minHeight: 220,
    borderRadius: 15,
  },
  UserProfile: {
    marginTop: 0,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: 'var(--secondary-border)',
    paddingTop: 8,
    paddingBottom: 8,
  },
  ChatAvatar24: {
    width: 24,
    height: 24,
  },
  ChatAvatar32: {
    width: 32,
    height: 32,
  },
  ImgCover: {
    resizeMode: 'cover',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  ProfileMedia: {
    width: 44,
    height: 44,
    marginBottom: 0,
  },
  ProfileMedia100: {
    width: 100,
    height: 100,
    marginBottom: 0,
    borderRadius: '50%',
  },
  ProfileImg: {
    width: '100%',
    height: '100%',
  },
  ProfileInfo: {
    marginLeft: 8,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'between',
  },
  ProfileName: {
    ...baseFontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: 'var(--primary-white)',
  },
  FollowText: {
    ...baseFontFamily,
    marginLeft: 'auto',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 15,
    color: 'var(--text-color)',
  },
  ListSF: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  TextNFT: {
    ...baseFontFamily,
    fontWeight: 500,
    fontSize: 12,
    color: 'var(--secondary-gray)',
    marginBottom: 8,
  },

  Text16: {
    ...baseFontFamily,
    marginTop: 8,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 20,
    color: 'var(--text-color)',
    marginBottom: 8,
  },
  Text18: {
    ...baseFontFamily,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 20,
    color: 'var(--text-color)',
  },
  Text12: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 18,
    color: 'var(--text-color)',
  },
  CommentIconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  AppListItemComment: {
    marginTop: 8,
  },
  CommentsItem: {
    // ...AppPaddingX,
    marginTop: 0,
    width: '100%',
    flexDirection: 'row',
    marginBottom: 16,
  },
  CommentsItemReplay: {
    paddingLeft: 60,
    paddingRight: 20,
  },
  CommentsMedia: {
    width: 32,
    height: 32,
    marginBottom: 0,
  },
  CommentsImg: {
    width: '100%',
    height: '100%',
  },
  CommentsInfo: {
    marginLeft: 8,
    flex: 1,
    // flexDirection: 'column',
    // flexWrap: 'wrap',
    // alignItems: 'center',
    // justifyContent: 'between',
  },
  CommentsName: {
    ...baseFontFamily,
    fontWeight: 600,
    fontSize: 14,
    color: 'var(--primary-white)',
  },
  CommentsText: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 6,
    display: 'inline',
    color: 'var(--secondary-gray)',
  },
  CommentsTime: {
    width: '100%',
  },
  CommentsReply: {
    ...baseFontFamily,
    marginTop: 4,
    fontWeight: 500,
    fontSize: 12,
    color: 'var(--primary-purple)',
  },
  CommentsTimeText: {
    ...baseFontFamily,
    marginTop: 4,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 15,
    color: 'var(--secondary-gray)',
    opacity: '0.9',
  },
  PlayerSongInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  PlayerControl: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  PlayerControlItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  PlayerBtnAction: {
    ...BtnPlayGradient,
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 24,
    marginRight: 24,
    // backgroundColor: 'var(--theme-color)',
  },
  PlayerCardBG: {
    position: 'relative',
  },
  StyleWaves: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9,
  },
  PlayerCard: {
    width: '100%',
    maxWidth: 280,
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginBottom: 32,
  },
  PlayerThumb: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 200,
    height: 200,
    borderWidth: 20,
    borderColor: '#2C2C32',
    borderRadius: '50%',
    overflow: 'hidden',
    boxShadow: '0px 0px 70px var(--primary-purple)',
  },
  PlayerThumbImg: {
    resizeMode: 'cover',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  AppSectionPlayer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  PlayerStickyBar: {
    height: 'var(--header-height)',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '5px 5px 15px var(--divider-color)',
  },
  PlayerStickyBarDesktop: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    zIndex: 9,
  },
  PlayerStickyBarContent: {
    ...AppPaddingX,
    height: 'var(--header-height-content)',
    // height: 'var(--header-height)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  PlayerSongText: {
    paddingLeft: 40,
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 15,
    color: 'var(--text-color)',
  },
  PlayerViewFullScreen: {
    zIndex: 9,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  NoCommentsContent: {
    // height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  ProductCount: {
    backgroundColor: 'var(--secondary-border)',
    padding: 15,
    borderRadius: 30,
  },
  InputCount: {
    ...baseFontFamily,
    textAlign: 'center',
    width: 50,
    fontSize: 18,
  },
  Modalcontainer: {
    backgroundColor: 'transparent',
  },
  ModalBody: {
    backgroundColor: 'rgba(0,0,0, 0.9)',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },

  CloseModal: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 24,
    height: 24,
  },
  CommentCloseModal: {
    top: -4,
    right: 0,
    width: 24,
    height: 24,
  },
  ModalBodyGradient: {
    maxWidth: 335,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingTop: 40,
    paddingRight: 24,
    paddingBottom: 40,
    paddingLeft: 24,
    // backgroundImage: 'linear-gradient(315deg, #6B3CBD 0%, #3467DE 100%)',
    backgroundColor: 'var(--bg-dark-blur)',
    backdropFilter: 'blur(30px)',
    textAlign: 'center',
  },
  modalVerticalCenter: {
    backgroundColor: 'red',
  },
  CommentmodalWrapper: {
    height: '50vh',
  },
  CommentModalContent: {
    backgroundColor: 'var(--bg-dark-blur)',
    backdropFilter: 'blur(30px)',
    maxWidth: '100%',
    width: 426,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    paddingTop: 16,
    paddingBottom: 16,
    position: 'relative',
  },
  CommentModalHeader: {
    width: '100%',
    paddingRight: 20,
    paddingLeft: 20,
  },
  CommentsModalBody: {
    width: '100%',
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: 'rgba(255,255,255,0.2)',
    marginTop: 16,
    overflowY: 'auto',
  },
  CommentsModalFooter: {
    width: '100%',
  },
  searchSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  CommentInputStyle: {
    position: 'relative',
    height: 39,
  },
  SendIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    opacity: 0.5,
  },

  inputComment: {
    height: 39,
    padding: 12,
  },
  DownShape: {
    position: 'absolute',
    bottom: '-16px',
    left: 0,
    right: 0,
    maxWidth: '100%',
    width: 426,
    paddingLeft: 60,
    marginLeft: 'auto',
    // marginRight: 'auto',
  },
  FormGroup: {
    marginBottom: 16,
  },

  ChatOptionsWrapper: {
    height: 'auto',
  },
  ChatAudioWrapper: {
    height: 116,
  },
  CraftsmodalWrapper: {
    height: '75vh',
  },
  modalMainView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
    backdropFilter: 'blur(12px)',
  },
  CloseBottomModal: {
    top: 0,
    right: 0,
    width: 14,
    height: 14,
  },
  ModalBottomContent: {
    // backgroundImage: 'linear-gradient(315deg, #6B3CBD 0%, #3467DE 100%)',
    backgroundColor: 'var(--back-btn-bg)',
    backdropFilter: 'blur(30px)',
    maxWidth: 426,
    width: '100vw',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    borderBottomEndRadius: 0,
    borderBottomStartRadius: 0,
    paddingTop: 16,
    paddingRight: 20,
    paddingBottom: 16,
    paddingLeft: 20,
    position: 'relative',
  },
  AudioModalBottomContent: {
    backgroundImage: 'none',
    backgroundColor: '#29334F',
    paddingTop: 28,
    paddingBottom: 28,
  },
  ModalBottomHeader: {
    width: '100%',
    textAlign: 'center',
    paddingTop: 32,
  },
  ModalBottomBody: {
    width: '100%',
    flex: 1,
    marginTop: 16,
    overflowY: 'auto',
  },
  CraftssModalFooter: {
    width: '100%',
  },
  CraftsInputStyle: {
    flex: 1,
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 20,
    position: 'relative',
  },
  ModalListItem: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: 'var(--secondary-border)',
  },
  ArtistItem: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: 'var(--secondary-border)',
  },
  authLink: {
    position: 'absolute',
    top: 20,
    alignItems: 'center',
    marginLeft: 24,
  },
  ChatOnline: {
    width: 8,
    height: 8,
    position: 'absolute',
    right: 1,
    bottom: 7,
  },
  ChatItem: {
    marginBottom: 16,
  },
  ChatMsg: {
    backgroundImage: 'linear-gradient(135deg, #A933CA 0%, #E596FC 100%)',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)',
    paddingTop: 8,
    paddingLeft: 16,
    paddingBottom: 8,
    paddingRight: 16,
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    borderBottomStartRadius: 0,
    borderBottomEndRadius: 8,
  },
  ChatMsgEnd: {
    backgroundImage: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    borderBottomStartRadius: 8,
    borderBottomEndRadius: 0,
  },
  ChatMsgImg: {
    resizeMode: 'cover',
    objectFit: 'cover',
    width: 205,
    height: 125,
    overflow: 'hidden',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    borderBottomStartRadius: 8,
    borderBottomEndRadius: 0,
  },
  ChatMsgImgStart: {
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    borderBottomStartRadius: 0,
    borderBottomEndRadius: 8,
  },
});

import { SET_PLAYLIST, SET_SONG } from './MusicActions';

const initialState = {
  song: {},
  playlist: [],
  playlistSource: '',
};

const MusicReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SONG:
      return {
        song: {
          ...state.song,
          ...action.payload,
        },
        playlist: state.playlist,
        playlistSource: state.playlistSource,
      };

    case SET_PLAYLIST:
      return {
        song: state.song,
        playlist: [...action.payload.songs],
        playlistSource: action.payload.source,
      };

    default:
      return state;
  }
};

export default MusicReducer;

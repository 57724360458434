import { StyleSheet } from 'react-native';
import { baseFontFamily, BtnFenixGradient, PrimaryGradient, BtnGradient, BtnPlayGradient } from './common.app';

export default StyleSheet.create({
  buttonStyle: {
    // ...BtnGradient,
    paddingTop: 6,
    paddingRight: 18,
    paddingBottom: 6,
    paddingLeft: 18,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: 'var(--secondary-gray)',
  },

  buttonTextStyle: {
    ...baseFontFamily,
    color: 'var(--primary-purple)',
    fontWeight: 500,
    fontSize: 14,
    textTransform: 'capitalize',
  },
  myLinkTextStyle: {
    ...baseFontFamily,
    color: 'var(--secondary-gray)',
    fontWeight: 500,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  Socialbutton: {
    // ...BtnGradient,
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },

  ChatUploadbutton: {
    backgroundImage: 'linear-gradient(135deg, #9F77FE 0%, #0EE1DF 100%)',
    borderRadius: '50%',
    width: 52,
    height: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 4,
  },

  Recordbutton: {
    backgroundImage: 'linear-gradient(135deg, #EB5AF8 0%, #FF7F00 100%)',
    borderRadius: '50%',
    width: 52,
    height: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // borderWidth: 2,
    // borderColor: '#ffffff',
  },

  BtnFenix: {
    ...BtnFenixGradient,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Btn24: {
    backgroundImage: 'linear-gradient(135deg, #EB5AF8 0%, #FF7F00 100%)',
    borderRadius: '50%',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  BtnPlayaudio: {
    backgroundImage: 'linear-gradient(135deg, #9F77FE 0%, #0EE1DF 100%)',
    borderRadius: '50%',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    paddingLeft: 2,
  },
  BtnNFTPlay: {
    ...BtnPlayGradient,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 32,
    height: 32,
  },
  Btn30: {
    ...BtnPlayGradient,
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  AlbumsButtonItem: {
    marginLeft: 5,
    marginRight: 5,
    minWidth: 142,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'var(--btn-rgba-color)',
  },
  AlbumsButtoText: {
    ...baseFontFamily,
    color: 'var(--button-text-color)',
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 17,
  },
  BtnPrimaryGradient: {
    ...PrimaryGradient,
    height: 48,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 6,
    paddingRight: 18,
    paddingBottom: 6,
    paddingLeft: 18,
  },
  BtnPrimaryGradientText: {
    ...baseFontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 18,
    color: 'var(--button-text-color)',
    textTransform: 'uppercase',
  },
  BtnOutlineLight: {
    // ...PrimaryGradient,
    height: 48,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 6,
    paddingRight: 18,
    paddingBottom: 6,
    paddingLeft: 18,
    borderWidth: 1,
    borderColor: 'var(--secondary-light-gray)',
  },
  BtnOutlineLightText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 18,
    color: 'var(--secondary-light-gray)',
    textTransform: 'uppercase',
  },
  BtnCloseModal: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 142,
    height: 36,
    lineHeight: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'var(--btn-rgba-color)',
    color: 'var(--button-text-color)',
  },
  Linkbutton: {
    // marginBottom: 8,
    textAlign: 'center',
  },
  BtnAuthGradient: {
    ...baseFontFamily,
    backgroundImage: 'linear-gradient(135deg, #00D27D 0%, #3F99F0 100%)',
    height: 55,
    borderRadius: '27.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)',
  },
  BtnAuthText: {
    ...baseFontFamily,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 20,
    color: '#ffffff',
  },
  // BtnOutlineLight: {
  //   ...baseFontFamily,
  //   backgroundColor: 'transparent',
  //   height: 55,
  //   borderRadius: '27.5px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   borderWidth: 1,
  //   borderColor: 'var(--btn-rgba-color)',
  // },
});

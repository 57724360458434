import { HIDE_NOTIF, SHOW_NOTIF } from './NotificationActions';

const initialState = {
  message: '',
  action: '',
  duration: 0,
  display: false,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIF:
      return {
        ...action.payload,
      };

    case HIDE_NOTIF:
      return initialState;

    default:
      return state;
  }
};

export default NotificationReducer;
